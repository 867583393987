import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PlanQA, PlanModel } from "@blueplan/types";
import { formatImageUrl } from "@blueplan/core/src/util/imageUtil";
import "./planAnnotator.scss";
import classNames from "classnames";
import { CanvasWithAnnotations } from "./CanvasWithAnnotations";
import { useCurrenPlanQaData } from "../../../atom/planQaAtom";
import { componentToCanvasAnnotation } from "../../canvas/util/canvasAnnotationUtil";
import { onboardingApi } from "@blueplan/core/src/api/onboardingApi";
import { useSetRecoilState } from "recoil";
import { activeCanvasContextAtom } from "../planManagment/PlanViewer";
import { loadImageElement } from "../../canvas/util/canvasImageUtil";

interface PlanViewerProps {
  plan: PlanModel;
}

export const PlanAnnotator = ({ plan }: PlanViewerProps) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [renderedPlanQa, setRenderedPlanQa] = useState<PlanQA | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const currentPlanQa = useCurrenPlanQaData();
  const [fullPlan, setFullPlan] = useState<PlanModel | null>(null);
  const setActiveCanvasContext = useSetRecoilState(activeCanvasContextAtom);

  const selectPage = useCallback(
    (pageIndex: number) => {
      if (!fullPlan) {
        return;
      }

      const newPage = fullPlan.pages[pageIndex];
      const imageUrl = formatImageUrl(newPage.pageImageBlob);
      setActiveCanvasContext((old) => ({ ...old, isReady: false }));
      loadImageElement(imageUrl, (imgEle) => {
        setActiveCanvasContext((old) => ({
          ...old,
          pageSize: { width: imgEle.naturalWidth, height: imgEle.naturalHeight },
          isReady: true,
          pageIndex: pageIndex,
        }));
        setPageNumber(pageIndex);
      });
    },
    [setPageNumber, fullPlan, setActiveCanvasContext]
  );

  useEffect(() => {
    if (renderedPlanQa?.planQaId !== currentPlanQa.nullableData()?.planQaId && fullPlan) {
      setRenderedPlanQa(currentPlanQa.data() ?? null);
      toolbarRef?.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      selectPage(0);
    }
  }, [renderedPlanQa, selectPage, setRenderedPlanQa, currentPlanQa, toolbarRef, fullPlan]);

  const sidebar = useMemo(() => {
    return plan.pages.map((page, index) => (
      <div
        className={classNames("item", { selected: pageNumber === index })}
        onClick={() => selectPage(index)}
        key={page.id}
      >
        <img src={formatImageUrl(page.thumbnailBlob)} />
        <div className="index">{index + 1}</div>
      </div>
    ));
  }, [plan, pageNumber, selectPage]);

  const imageBlob = useMemo(() => plan.pages[pageNumber].pageImageBlob, [plan, pageNumber]);

  useEffect(() => {
    if (!fullPlan)
      onboardingApi.planPages(plan.id).then((fullPlan) => {
        setFullPlan(fullPlan);
      });
  }, [fullPlan, plan.id]);

  const extraAnnotations = useMemo(() => {
    if (!fullPlan) {
      return [];
    }

    const page = fullPlan.pages[pageNumber];
    return page.components.map((component) =>
      componentToCanvasAnnotation(component, pageNumber, { color: "ff0000" })
    );
  }, [pageNumber, fullPlan]);

  const isLoaded = imageBlob && currentPlanQa.isDone();

  return (
    <div className="flex plan-annotator">
      <div className="sidebar flex" ref={toolbarRef}>
        {sidebar}
      </div>
      {isLoaded && (
        <CanvasWithAnnotations
          pageImageBlob={imageBlob}
          pageNumber={pageNumber}
          planQa={currentPlanQa.data()}
          extraAnnotations={extraAnnotations}
        />
      )}
    </div>
  );
};
