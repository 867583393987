import { atom } from "recoil";
import { DataState, ProjectModel } from "@blueplan/types";

export interface ProjectsAtom {
  state: DataState;
  list: ProjectModel[];
}
export const projectsAtom = atom<ProjectsAtom>({
  key: "ProjectsAtom",
  default: {
    state: DataState.NOT_LOADED,
    list: [],
  },
});
