import React, { useRef } from "react";
import { AppSideMenu } from "./project/AppSideMenu";
import { generatePath, Outlet } from "react-router-dom";
import { useLoadCurrentProject } from "@blueplan/core/src/project/hook/useLoadCurrentProject";
import { useLoadProjects } from "@blueplan/core/src/project/hook/useLoadProjects";
import "./projectStyle.scss";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentProjectsAtom } from "@blueplan/core/src/atom/currentProjectAtom";
import classNames from "classnames";
import { Breadcrumbs } from "../app/Breadcrumbs";
import { usePollPlans } from "./hook/usePollPlans";
import { useFinishLoadInitiateData } from "../../hooks/useFinishLoadInitiateData";
import { AppLogoLoader } from "@blueplan/core/src/uiComponents/AppLogoLoader";
import { useLoadCurrentPlan } from "./hook/useLoadCurrentPlan";
import { useUpdateCurrentPlanQa } from "./hook/useUpdateCurrentPlanQa";
import { useLoadPlanQaList } from "./planQa/hook/useLoadPlanQaList";
import { useLoadPlanReports } from "./hook/useLoadPlanReports";
import { useLoadPlanReport } from "./hook/useLoadPlanReport";
import { GoSidebarCollapse } from "react-icons/go";
import { appMenuStateAtom } from "../../atom/MenuStateAtom";
import { usePollReportsStatus } from "./hook/usePollReportsStatus";
import { Button, ButtonColor } from "@blueplan/core/src/theme/Button";
import { ROUTER_PATHS } from "../app/routerPaths";
import { useLoadPlanQaQuestions } from "./planQa/hook/useLoadPlanQaQuestions";
import { useGetJurisdictionBookMapping } from "./hook/useGetJurisdictionBookMapping";

export const AppOutlet = () => {
  const currentProject = useRecoilValue(currentProjectsAtom);
  const ref = useRef<HTMLDivElement>(null);
  const finishLoadInitiateData = useFinishLoadInitiateData();
  const setAppMenuState = useSetRecoilState(appMenuStateAtom);

  useLoadProjects();
  useLoadCurrentProject(ROUTER_PATHS.ROOT);
  useLoadCurrentPlan();
  useLoadPlanQaList();
  usePollPlans();
  useUpdateCurrentPlanQa();
  useLoadPlanReports();
  useLoadPlanReport();
  usePollReportsStatus();
  useLoadPlanQaQuestions();
  useGetJurisdictionBookMapping();

  if (!finishLoadInitiateData) {
    return <AppLogoLoader />;
  }

  return (
    <div className="flex outlet-project-container">
      <AppSideMenu />
      <div className="outlet-container" id="outlet-container" ref={ref}>
        <div className={classNames("project-header flex")}>
          <div className="flex address-container">
            <div
              className="menu-collapse"
              onClick={() => setAppMenuState((old) => ({ ...old, collapse: !old.collapse }))}
            >
              <GoSidebarCollapse />
            </div>
            <div className="flex address">
              <Breadcrumbs />
            </div>
            <div className="bin flex">
              {/*{currentProject.model*/}
              {/*  ? getCityId(currentProject.model.address)*/}
              {/*  : null}*/}
            </div>
            {currentProject.model && (
              <div className="view-plan">
                <Button
                  color={ButtonColor.BORDER}
                  url={generatePath(ROUTER_PATHS.PLAN_VIEWER, {
                    projectId: currentProject.model?.projectId,
                  })}
                >
                  PDF
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="outlet-padding">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
