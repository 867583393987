import { BaseApi } from "@blueplan/core/src/api/BaseApi";
import { CityName, FullPlanReport, PlanReport, SectionComplianceReport } from "@blueplan/types";

class PlanReportApi extends BaseApi {
  async create(planId: string, cityName: CityName, presetId: string): Promise<PlanReport> {
    return await this.post(`admin/create`, { planId, cityName, presetId }, {}, 10000 * 6);
  }

  async getReport(reportId: string): Promise<FullPlanReport> {
    return await this.get(`get?reportId=${reportId}`);
  }

  async getReportAdmin(reportId: string): Promise<FullPlanReport> {
    return await this.get(`get-admin?reportId=${reportId}`);
  }

  async getAll(planId: string): Promise<PlanReport[]> {
    return await this.get(`all?planId=${planId}`);
  }

  async adminReplaceSectionComplianceReport(
    sectionComplianceReportId: string
  ): Promise<SectionComplianceReport> {
    return await this.get(
      `admin/replace-section?sectionComplianceReportId=${sectionComplianceReportId}`,
      undefined,
      undefined,
      180000
    );
  }

  async adminAddSectionComplianceReport(
    reportId: string,
    sectionPath: string,
    codeYear: number
  ): Promise<SectionComplianceReport> {
    return await this.get(
      `admin/add-section?reportId=${reportId}&sectionPath=${sectionPath}&codeYear=${codeYear}`,
      undefined,
      undefined,
      180000
    );
  }
}

export const planReportApi = new PlanReportApi("plan-report");
