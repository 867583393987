import { CityName, ReviewResultType } from "@blueplan/types";
import { DATA_ATTRIBUTES, DATA_EL_TYPE } from "./dataAttributes";
import {
  getReportResultClassForStr,
  getReportResultText,
} from "@blueplan/core/src/report/reportUtil";

const linksRegex = /\[(.*?)\]\((\{.*?\})\)/;

export interface BuildingCodeCitationLegacy {
  // The type, year, city are deprecated and no longer in used. We must keep it for backward compatability.
  type: string;
  year: number;
  cityName: CityName;
}

export interface BuildingCodeCitation extends BuildingCodeCitationLegacy {
  bookId?: string;
  section: string;
}

export interface PageReferenceCitation {
  type: string;
  pageIndex: number;
}

export interface ComponentReference {
  type: string;
  pageIndex: number;
  componentId: string;
  componentTitle: string;
}

export interface cityNameReference {
  type: string;
  name: string;
}

const getBuildingCodeSectionCitation = (citation: string) =>
  `<span ${DATA_ATTRIBUTES.TYPE}=${DATA_EL_TYPE.BUILDING_CODE} ${DATA_ATTRIBUTES.BUILDING_CODE}='${citation}'></span>`;

const getPageReferenceCitation = (page: string) =>
  `<span ${DATA_ATTRIBUTES.TYPE}=${DATA_EL_TYPE.PAGE_CITATION} ${DATA_ATTRIBUTES.PAGE}='${page}'></span>`;

const getComponentReferenceCitation = (page: string, componentId: string, componentTitle: string) =>
  `<span ${DATA_ATTRIBUTES.TYPE}=${DATA_EL_TYPE.COMPONENT_CITATION}
        ${DATA_ATTRIBUTES.PAGE}='${page}'
        ${DATA_ATTRIBUTES.COMPONENT_ID}='${componentId}'
        ${DATA_ATTRIBUTES.COMPONENT_TITLE}='${componentTitle}'></span>`;

const getCityNameCitation = (cityName: string) =>
  `<span ${DATA_ATTRIBUTES.TYPE}=${DATA_EL_TYPE.CITY_NAME} ${DATA_ATTRIBUTES.CITY_NAME}=${cityName}></span>`;

const getReviewResultType = (resultType: string) =>
  `<span ${DATA_ATTRIBUTES.TYPE}=${
    DATA_EL_TYPE.REVIEW_RESULT
  } class='result-type ${getReportResultClassForStr(
    resultType
  )}'>${getReportResultText(resultType)}</span>`;

const replaceAt = (text: string, indexPosition: number, replaceLength: number, chr: string) => {
  if (indexPosition > text.length - 1) return text;
  let out = text.substring(0, indexPosition) + text.substring(indexPosition + replaceLength);
  out = out.slice(0, indexPosition) + chr + out.slice(indexPosition);
  return out;
};

export interface TextAndCitations {
  text: string;
  citations: BuildingCodeCitation[];
}

export const detectMarkdownLinks = (text: string) => {
  let citations: BuildingCodeCitation[] = [];
  let match;
  let counter = 0;
  while ((match = new RegExp(linksRegex).exec(text)) != null) {
    try {
      const { text: newText, citations: newCitations } = replaceTextWithFinds(text, match);
      citations = [...citations, ...newCitations];
      text = newText;
    } catch (_e) {
      text = replaceTextWithFailedToFind(text, match);
    }
    counter++;
    if (counter > 100) {
      console.error("preventing infinite loop inside markdown util");
      console.error(text);
      text = replaceTextWithFailedToFind(text, match);
    }
  }

  return { text, citations };
};

export const replaceReviewResultType = (text: string) => {
  Object.values(ReviewResultType)
    .reverse()
    .forEach((resultType) => {
      text = text.replace(resultType, getReviewResultType(resultType));
    });
  return text;
};

/** We limit the number of iteration because the AI returns some bad value. */
export const processLLMOutputToStringHtml = (originalText: string): TextAndCitations => {
  let { text } = detectMarkdownLinks(originalText);
  text = replaceReviewResultType(text);
  const { citations } = detectMarkdownLinks(originalText);
  return { text, citations };
};

export const replaceTextWithFinds = (text: string, match: RegExpMatchArray) => {
  const citations: BuildingCodeCitation[] = [];
  const buildingCodeCitation = JSON.parse(match[2]) as BuildingCodeCitation;
  if (buildingCodeCitation && buildingCodeCitation?.type === "section") {
    text = replaceAt(
      text,
      match.index || 0,
      match[0].length,
      getBuildingCodeSectionCitation(match[2])
    );
    citations.push(buildingCodeCitation);
  }

  const pageReferenceCitation = JSON.parse(match[2]) as PageReferenceCitation;
  if (pageReferenceCitation?.type === "pageReference") {
    text = replaceAt(text, match.index || 0, match[0].length, getPageReferenceCitation(match[1]));
  }

  const componentReferenceCitation = JSON.parse(match[2]) as ComponentReference;
  if (componentReferenceCitation?.type === "componentReference") {
    text = replaceAt(
      text,
      match.index || 0,
      match[0].length,
      getComponentReferenceCitation(
        componentReferenceCitation.pageIndex + "",
        componentReferenceCitation.componentId,
        componentReferenceCitation.componentTitle
      )
    );
  }

  const cityNameCitation = JSON.parse(match[2]) as cityNameReference;
  if (cityNameCitation.type === "cityName") {
    text = replaceAt(
      text,
      match.index || 0,
      match[0].length,
      getCityNameCitation(cityNameCitation.name)
    );
  }

  return { text, citations };
};

export const replaceTextWithFailedToFind = (text: string, match: RegExpMatchArray) => {
  return replaceAt(text, match.index || 0, match[0].length, "[Error in link]");
};
