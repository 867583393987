import React from "react";
import { CityName } from "@blueplan/types";
import "./sideBySide.scss";
import { PlanViewer } from "../projects/planManagment/PlanViewer";
import { CanvasAnnotation } from "../canvas/util/canvasAnnotationUtil";
import { useFullPlanData } from "@blueplan/core/src/atom/fullPlanAtom";
import { useLoadFullPlan } from "../projects/hook/useLoadFullPlan";

export interface SideBySideContent<T> {
  items: T[];
}

interface PlanReportProps<T> {
  items: T[];
  cityName: CityName;
  RightHandSide: React.ComponentType<SideBySideContent<T>>;
  title: React.ReactNode;
  additionalTitle?: React.ReactNode;
  enableAnnotationTooltip: boolean;
  getFilteredCanvasAnnotations: (pageIndex: number) => CanvasAnnotation[];
}

export const SideBySideView = <T,>({
  items,
  RightHandSide,
  title,
  additionalTitle,
  enableAnnotationTooltip,
  getFilteredCanvasAnnotations = () => [],
}: PlanReportProps<T>) => {
  const fullPlanData = useFullPlanData();
  useLoadFullPlan();

  if (fullPlanData.isNotDone()) {
    return null;
  }

  return (
    <div className="side-by-side-view flex">
      <div className="flex container">
        <div className="flex plan-container">
          <h2 className="flex">
            <span>{title}</span>
            <div className="created">{additionalTitle}</div>
          </h2>
          <PlanViewer
            getCanvasAnnotations={getFilteredCanvasAnnotations}
            enableAnnotationTooltip={enableAnnotationTooltip}
          />
        </div>
        <RightHandSide items={items as T[]} />
      </div>
    </div>
  );
};
