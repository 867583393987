import React from "react";
import { flexRender, Table as TableI } from "@tanstack/react-table";

interface TableProps<T> {
  table: TableI<T>;
  classes?: string;
  onRowClick?: (row: T) => void;
  getRowClass?: (row: T) => string;
}

export const Table = <T,>({
  table,
  classes,
  onRowClick = () => {},
  getRowClass = () => "",
}: TableProps<T>) => {
  return (
    <table className={classes}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            onClick={() => onRowClick(row.original)}
            className={getRowClass(row.original)}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className={cell.column.columnDef.meta?.additionalClass}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
