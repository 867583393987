import { PlanModel } from "@blueplan/types";
import { BaseApi } from "@blueplan/core/src/api/BaseApi";

class PlanApi extends BaseApi {
  async getPlans(projectId: string): Promise<PlanModel[]> {
    return this.get(`all?projectId=${projectId}`);
  }

  async addPlan(projectId: string, fildId: string): Promise<PlanModel> {
    return this.get(`add?projectId=${projectId}&fileId=${fildId}`);
  }

  getFullPlan(planId: string): Promise<PlanModel> {
    return this.get(`get?planId=${planId}`);
  }
}

export const planApi = new PlanApi("plan");
