import { useCallback, useEffect } from "react";
import { DataLoadingStates } from "@blueplan/types";
import { questionApi } from "../../../api/questionApi";
import { usePlanQaQuestions } from "../../../atom/questionAtom";

export const usePollPlanQuestionStatus = (planQuestionId: string) => {
  const planQaQuestions = usePlanQaQuestions();

  const finishLoading = useCallback(() => {
    return (
      planQaQuestions.data().find(({ id }) => id === planQuestionId)?.status ===
      DataLoadingStates.COMPLETED
    );
  }, [planQaQuestions, planQuestionId]);

  const maybeUpdateReports = useCallback(() => {
    if (finishLoading()) {
      return;
    }

    questionApi.getQuestion(planQuestionId).then((newQuestion) => {
      // Replace the updated question data.
      planQaQuestions.updateFn((old) => {
        if (!old.model) {
          return old;
        }

        const model = old.model.map((oldQuestion) => {
          if (oldQuestion.id === planQuestionId) {
            return newQuestion;
          }
          return oldQuestion;
        });
        return { ...old, model };
      });
    });
  }, [finishLoading, planQaQuestions, planQuestionId]);

  useEffect(() => {
    const interval = setInterval(() => {
      maybeUpdateReports();
      if (finishLoading()) {
        clearInterval(interval);
      }
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, [maybeUpdateReports, finishLoading]);
};
