import React from "react";
import { Input } from "../../other/Input";
import { FaExclamation, FaSearch } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";
import { planReportFiltersAtom } from "../atom/PlanReportFiltersAtom";
import {
  ReportFiltersTooltip,
  REPORTS_FILTER_TOOLTIP_KEY,
} from "../reportFilters/ReportFiltersTooltip";
import { Button } from "@blueplan/core/src/theme/Button";
import { FaGear } from "react-icons/fa6";
import "./reportResultFilter.scss";
import { useFullPlanData } from "@blueplan/core/src/atom/fullPlanAtom";
import { activeCanvasContextAtom } from "../../projects/planManagment/PlanViewer";

export const ReportResultFilter = () => {
  const [{ searchText, filterByPage }, setPlanReportFilters] =
    useRecoilState(planReportFiltersAtom);
  const fullPlanData = useFullPlanData();
  const updateInput = (searchText: string) => {
    setPlanReportFilters((old) => ({ ...old, searchText }));
  };
  const { pageIndex } = useRecoilValue(activeCanvasContextAtom);

  const currentSheet = fullPlanData.data().pages[pageIndex].sheetDetails.sheetNumber;

  return (
    <div className="report-result-filter">
      <div className="flex data-box">
        <div className="filters-item">
          <Input
            value={searchText}
            onChange={updateInput}
            placeholder="Search"
            IconLeft={FaSearch}
          />
        </div>
        <div className="filters-item">
          <Button IconLeft={FaGear} classes="fitlers-button" tooltipId={REPORTS_FILTER_TOOLTIP_KEY}>
            Filters
          </Button>
          <ReportFiltersTooltip />
        </div>
      </div>
      {filterByPage && (
        <div className="hint flex">
          <FaExclamation />
          <span>
            Only showing section relevant for sheet {currentSheet} (page {pageIndex + 1})
          </span>
        </div>
      )}
    </div>
  );
};
