import { BookMetadata, CityName, ProjectModel } from "@blueplan/types";
import { BaseApi } from "./BaseApi";

export type CityToBooks = BookMetadata[];

class ProjectApi extends BaseApi {
  async create(placeId: string): Promise<ProjectModel> {
    return this.get(`create?placeId=${placeId}`);
  }

  async getAll(): Promise<ProjectModel[]> {
    return this.get("all");
  }

  updateProjectDetails(projectModel: ProjectModel) {
    this.post("update", projectModel);
  }

  updatePlanTitle(planId: string, title: string) {
    this.get(`update-title?planId=${planId}&title=${title}`);
  }

  updateActivePlan(projectId: string, planId: string) {
    this.get(`set-active-plan?projectId=${projectId}&planId=${planId}`);
  }

  getJurisdictionBookMapping(cityName: CityName): Promise<CityToBooks> {
    return this.get(`books-mapping?cityName=${cityName}`);
  }
}

export const projectApi = new ProjectApi("project");
