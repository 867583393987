import { BaseApi } from "@blueplan/core/src/api/BaseApi";
import { BuildingCodeRecord, CityName, ReportPreset } from "@blueplan/types";

class BuildingCodeApi extends BaseApi {
  async getCodesForCityAndYear(
    cityName: CityName,
    year: number,
    sections: string[]
  ): Promise<BuildingCodeRecord[]> {
    return this.get(`get?cityName=${cityName}&year=${year}&sections=${sections.join(",")}`);
  }

  async getCodesForBookId(bookId: string, sections: string[]): Promise<BuildingCodeRecord[]> {
    return this.get(`get?bookId=${bookId}&sections=${sections.join(",")}`);
  }

  async getReportPreset(projectId: string): Promise<ReportPreset[]> {
    return this.get(`report-presets?projectId=${projectId}`);
  }
}

export const buildingCodeApi = new BuildingCodeApi("building-code");
