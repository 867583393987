import React, { useMemo } from "react";
import { SideBySideContent } from "./SideBySideView";
import { BookMetadata, PlanQuestion } from "@blueplan/types";
import { useRecoilValue } from "recoil";
import { currentProjectsAtom } from "@blueplan/core/src/atom/currentProjectAtom";
import { QAAnswer } from "../projects/questions/QAAnswer";
import { questionApi } from "../../api/questionApi";
import { useCurrenPlanQaData } from "../../atom/planQaAtom";
import "./sideBySideContentForQa.scss";
import { QuestionBox } from "../projects/questions/QuestionBox";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { usePlanQaQuestions } from "../../atom/questionAtom";

export const SideBySideContentForPlanQa = ({ items }: SideBySideContent<PlanQuestion>) => {
  const { model: project } = useRecoilValue(currentProjectsAtom);
  const currentPlanQa = useCurrenPlanQaData();
  const { activePlan } = useActivePlan();
  const planQaQuestions = usePlanQaQuestions();

  const handleQuestion = (
    question: string,
    booksMetadata: BookMetadata[],
    onSubmit: () => void
  ) => {
    if (!currentPlanQa.data || !project) {
      return;
    }
    const booksMetadataIds = booksMetadata.map((booksMetadata) => booksMetadata.id);
    questionApi
      .ask(currentPlanQa.data().planQaId, question, booksMetadataIds)
      .then((question) => {
        planQaQuestions.updateFn((old) => ({
          ...old,
          model: [...(old.model ?? []), question],
        }));
      })
      .finally(() => {
        onSubmit();
      });
  };

  const answers = useMemo(() => {
    if (!project) {
      return null;
    }

    return [...items].reverse().map((item) => <QAAnswer {...item} key={item.id} />);
  }, [items, project]);

  if (!activePlan || !project) {
    return null;
  }

  return (
    <div className="right-hand-size-for-qa flex">
      <QuestionBox handleSubmit={handleQuestion} />
      <div className="results flex">{answers}</div>
    </div>
  );
};
