import { BaseApi } from "./BaseApi";
import { OktaUserInfo, UIUser } from "@blueplan/types";

class LoginApi extends BaseApi {
  async auth(token: string, orgId: string): Promise<UIUser> {
    const tokenWithOrg = token;
    localStorage.setItem("accessToken", token);
    localStorage.setItem("orgId", orgId);
    return await this.get<OktaUserInfo>("", tokenWithOrg, orgId);
  }

  async logout() {
    return await this.get("logout");
  }
}

export const authApi = new LoginApi("user");
