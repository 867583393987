import React from "react";
import "./noAuthPage.scss";
import { Button, ButtonColor } from "../../theme/Button";
import { useAuth } from "@blueplan/core/src/auth/useAuth";

interface NoAuthPageProps {
  rootPath: string
}

export const NoAuthPage = ({ rootPath}: NoAuthPageProps) => {
  const { logout } = useAuth(rootPath);
  return (
    <div className="no-auth-page">
      <div className="logout">
        <Button onClick={() => logout()} color={ButtonColor.TRANSPARENT}>
          Logout
        </Button>
      </div>
      <h1>We are in closed beta!</h1>
      <div className="inner">
        <div className="invite">
          We are currently onboarding new clients. If you are interested, please contact us at
          daniel@blueplanai.com or use the contact form.
        </div>
        <div className="onboarding">
          Our team is currently onboarding new clients, and we're carefully reviewing your details
          to ensure a smooth and personalized experience.
        </div>
      </div>
    </div>
  );
};
