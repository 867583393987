import { PagesIndex } from "../../planReport/PagesIndex";
import { PageCanvas } from "../../sideBySide/PageCanvas";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./planViewer.scss";
import { useHighlightedPageAndComponentListener } from "./useHighlightedPageAndComponentListener";

import { CanvasAnnotation } from "../../canvas/util/canvasAnnotationUtil";
import { atom, useRecoilState } from "recoil";
import { useFullPlanData } from "@blueplan/core/src/atom/fullPlanAtom";
import { loadImageElement } from "../../canvas/util/canvasImageUtil";
import { formatImageUrl } from "@blueplan/core/src/util/imageUtil";
import { Size } from "../../../model/math";

interface ActiveCanvasContextAtom {
  isReady: boolean;
  pageSize: Size;
  pageIndex: number;
}

export const activeCanvasContextAtom = atom<ActiveCanvasContextAtom>({
  key: "activeCanvasContextAtom",
  default: {
    isReady: false,
    pageSize: { width: 0, height: 0 },
    pageIndex: 0,
  },
});

interface PlanViewerProps {
  getCanvasAnnotations: (pageIndex: number) => CanvasAnnotation[];
  enableAnnotationTooltip: boolean;
}

export const PlanViewer = ({
  enableAnnotationTooltip,
  getCanvasAnnotations = () => [],
}: PlanViewerProps) => {
  const [{ isReady, pageIndex }, setActiveCanvasContextAtom] =
    useRecoilState(activeCanvasContextAtom);
  const fullPlanData = useFullPlanData();
  const [firstLoad, setFirstLoad] = useState(true);

  const filteredAnnotations = useMemo(
    () => getCanvasAnnotations(pageIndex),
    [getCanvasAnnotations, pageIndex]
  );

  const page = useMemo(() => fullPlanData.data().pages[pageIndex], [fullPlanData, pageIndex]);

  const handleImageChange = useCallback(
    (newPageIndex: number) => {
      setActiveCanvasContextAtom((old) => ({
        ...old,
        isReady: false,
        pageIndex: newPageIndex,
      }));

      const newPage = fullPlanData.data().pages[newPageIndex];
      const imageUrl = formatImageUrl(newPage.pageImageBlob);
      loadImageElement(imageUrl, (imgEle) => {
        setActiveCanvasContextAtom((old) => ({
          ...old,
          pageSize: { width: imgEle.naturalWidth, height: imgEle.naturalHeight },
          isReady: true,
        }));
      });
    },
    [fullPlanData, setActiveCanvasContextAtom]
  );

  const setPageIndex = useCallback(
    (newPageIndex: number) => {
      if (fullPlanData.isNotDone()) {
        return;
      }

      if (
        newPageIndex > -1 &&
        newPageIndex < fullPlanData.data().pages.length &&
        // For page index 0, we need to do the initial load.
        (newPageIndex !== pageIndex || pageIndex === 0)
      ) {
        handleImageChange(newPageIndex);
      }
    },
    [handleImageChange, pageIndex, fullPlanData]
  );

  useEffect(() => {
    if (firstLoad) {
      setPageIndex(0);
    }
    setFirstLoad(false);
  }, [firstLoad, setFirstLoad, setPageIndex]);

  useHighlightedPageAndComponentListener(setPageIndex);

  if (fullPlanData.isNotDone()) {
    return null;
  }

  return (
    <div className="plan-viewer flex">
      <PagesIndex setPageIndex={setPageIndex} />
      <PageCanvas
        setPageIndex={setPageIndex}
        page={page}
        filteredAnnotations={filteredAnnotations}
        handleFinishLoadImage={() => setActiveCanvasContextAtom((old) => ({ ...old }))}
        isReady={isReady}
        enableAnnotationTooltip={enableAnnotationTooltip}
      />
    </div>
  );
};
