import React from "react";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "@blueplan/core/src/auth/serverAuthFinishedAtom";
import "./avatar.scss";

export const OrgAvatar = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);

  if (!appLevelUser?.oktaOrg?.branding?.logo_url) {
    return null;
  }

  return (
    <div className="org-avatar">
      <img src={appLevelUser.oktaOrg?.branding.logo_url} />
    </div>
  );
};
