import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BlobStorage, PlanQA, Rect } from "@blueplan/types";
import { useAnnotationCanvasLayer } from "../../canvas/useAnnotationCanvasLayer";
import { useRecoilValue } from "recoil";
import { pageImageSizeAtom } from "../../../atom/pageImageSizeAtom";
import { formatImageUrl } from "@blueplan/core/src/util/imageUtil";
import { CanvasAnnotationMenu } from "../../canvas/CanvasAnnotationMenu";

import {
  CanvasAnnotation,
  planAnnotationToCanvasAnnotation,
} from "../../canvas/util/canvasAnnotationUtil";
import { activeCanvasContextAtom } from "../planManagment/PlanViewer";
import { useSelectCategoryAndAddAnnotationPopup } from "./useSelectCategoryAndAddAnnotationPopup";
import { MoveEvent } from "../../canvas/useDrag";

interface PlanCanvasProps {
  pageImageBlob: BlobStorage;
  planQa: PlanQA;
  pageNumber: number;
  extraAnnotations?: CanvasAnnotation[];
}

export const CanvasWithAnnotations = ({
  pageImageBlob,
  planQa,
  extraAnnotations = [],
}: PlanCanvasProps) => {
  const [selectedAnnotationKey, setSelectedAnnotationKey] = useState<string | null>(null);
  const { isReady } = useRecoilValue(activeCanvasContextAtom);
  const { SelectCategoryAndAddAnnotationPopup, showCategoryAndAddAnnotationPopup } =
    useSelectCategoryAndAddAnnotationPopup();

  const handleAddAnnotation = (pageNumber: number, rect: Rect, e: MoveEvent | undefined) => {
    if (!e) {
      return;
    }

    console.log(e);
    showCategoryAndAddAnnotationPopup({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      position: { x: e.pageX, y: e.pageY },
      pageNumber,
      planQaId: planQa.planQaId,
      rect,
    });
  };
  const annotations = useMemo(
    () =>
      Object.keys(planQa.planAnnotation).reduce(
        (acc, key) => [...acc, planAnnotationToCanvasAnnotation(planQa.planAnnotation[key])],
        [] as CanvasAnnotation[]
      ),
    [planQa.planAnnotation]
  );

  const handleSelectedAnnotationKey = useCallback(
    (key: string | null) => {
      setSelectedAnnotationKey(key);
    },
    [setSelectedAnnotationKey]
  );

  const combinedAnnotations = useMemo(
    () => [...annotations, ...extraAnnotations],
    [annotations, extraAnnotations]
  );

  const Canvas = useAnnotationCanvasLayer(
    combinedAnnotations,
    handleAddAnnotation,
    handleSelectedAnnotationKey
  );
  const { adjustedContainerWidth, adjustedContainerHeight } = useRecoilValue(pageImageSizeAtom);
  const [float, setFloat] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const target = document.getElementById("canvas");
      if (!target) {
        return;
      }

      const rect = target.getBoundingClientRect();
      const isBellow = !!rect && rect.bottom >= 0 && rect.y < 75;
      setFloat(isBellow);
    };

    document.getElementById("canvas-wrapper")?.addEventListener("scroll", handleScroll);
    const refCopy = menuRef.current;

    return () => {
      refCopy?.removeEventListener("scroll", handleScroll);
    };
  }, [menuRef, setFloat]);

  return useMemo(() => {
    return (
      <div className="canvas-wrapper" id="canvas-wrapper">
        {SelectCategoryAndAddAnnotationPopup}
        <CanvasAnnotationMenu
          annotationKey={selectedAnnotationKey}
          resetAnnotationKey={() => setSelectedAnnotationKey(null)}
          menuRef={menuRef}
          float={float}
        />
        {float && <div className="canvas-rect-menu-placeholder" />}
        <div className="canvas">
          {!isReady && <div className="loader">Loading</div>}
          <img
            className="under-image"
            src={formatImageUrl(pageImageBlob)}
            style={{
              width: adjustedContainerWidth,
              height: adjustedContainerHeight,
            }}
          />
          {Canvas}
        </div>
      </div>
    );
  }, [
    SelectCategoryAndAddAnnotationPopup,
    selectedAnnotationKey,
    float,
    isReady,
    pageImageBlob,
    adjustedContainerWidth,
    adjustedContainerHeight,
    Canvas,
  ]);
};
