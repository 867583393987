export const gEvent = (name: string, params?: Record<string, string>) => {
  // @ts-expect-error gTag is defined on the analytics script that is injected in run time.
  gtag("event", `fy_${name}`, params);
};

export const setGTag = (demoId: string) => {
  // @ts-expect-error gTag is defined on the analytics script that is injected in run time.
  gtag("config", "G-F77C8E99PN", {
    demo_id: demoId,
  });
};
