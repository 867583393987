import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { DataState } from "@blueplan/types";
import { projectApi } from "../../api/projectApi";
import { projectsAtom } from "../../atom/projectsAtom";

export const useLoadProjects = () => {
  const [projectsStore, setProjectStore] = useRecoilState(projectsAtom);

  useEffect(() => {
    if (projectsStore.state === DataState.NOT_LOADED) {
      setProjectStore((old) => ({ ...old, state: DataState.LOADING }));
      projectApi.getAll().then((projects) =>
        setProjectStore((old) => ({
          ...old,
          list: projects,
          state: DataState.LOADED,
        }))
      );
    }
  }, [projectsStore, setProjectStore]);
};
