import { useEffect } from "react";

export function useBrowserEventListenerEvent<T>(
  eventName: string,
  handler: (event: CustomEvent<T>) => void | Promise<void>
): void;

export function useBrowserEventListenerEvent<T>(
  eventName: string,
  handler: (params: T) => void | Promise<void>
): void;

export function useBrowserEventListenerEvent<T = void>(
  eventName: string,
  handler: (
    event: Event | KeyboardEvent | MouseEvent | T | (T extends void ? never : CustomEvent<T>)
  ) => void | Promise<void>
) {
  useEffect(() => {
    window.addEventListener(eventName, handler);

    return () => {
      window.removeEventListener(eventName, handler);
    };
  }, [eventName, handler]);
}
