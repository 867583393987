import { Component } from "@blueplan/types";
import { selector } from "recoil";
import { componentToCanvasAnnotation } from "../components/canvas/util/canvasAnnotationUtil";
import { fullPlanAtom } from "@blueplan/core/src/atom/fullPlanAtom";

export const fullPlanComponentsSelector = selector<Component[]>({
  key: "fullPlanComponentsSelector",
  get: ({ get }) => {
    const fullPlan = get(fullPlanAtom);
    return (
      fullPlan?.model?.pages.flatMap((page, pageIndex) =>
        page.components.map((component) => ({ ...component, pageIndex }))
      ) || []
    );
  },
});

export const fullPlanAnnotationsSelector = selector({
  key: "fullPlanAnnotationsSelector",
  get: ({ get }) =>
    get(fullPlanComponentsSelector).map((component) =>
      componentToCanvasAnnotation(component, component.pageIndex ?? 0)
    ),
});

export const fullPlanAnnotationsMapSelector = selector({
  key: "fullPlanAnnotationsMapSelector",
  get: ({ get }) =>
    Object.fromEntries(
      get(fullPlanAnnotationsSelector).map((component) => [component.componentId, component])
    ),
});
