import React, { ReactNode } from "react";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa";
import "./checkbox.scss";

interface CheckboxProps {
  checked: boolean;
  value: ReactNode;
  classes?: string;
  handleClick: () => void;
}

export const Checkbox = ({ checked, value, classes, handleClick }: CheckboxProps) => (
  <div onClick={handleClick} className="flex checkbox">
    <div
      className={classNames("box", {
        checked,
      })}
    >
      <FaCheck />
    </div>
    <div className={`value ${classes}`}>{value}</div>
  </div>
);
