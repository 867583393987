import React, { useState } from "react";
import { onboardingApi } from "@blueplan/core/src/api/onboardingApi";

interface AdminProcessPlanTempProps {
  planId: string;
}

export const AdminProcessPlanTemp = ({ planId }: AdminProcessPlanTempProps) => {
  const [planQaId, setPlanQaId] = useState("");
  const [callStatus, setCallState] = useState("");

  const markComplete = async () => {
    setCallState("Loading");
    try {
      await onboardingApi.markComplete(planId);
      setCallState("Success");
    } catch (_e) {
      setCallState("Failed to mark complete");
    }
  };

  const copyAnnotations = async () => {
    setCallState("Loading");
    try {
      await onboardingApi.copyAnnotations(planId, planQaId);
      setCallState("Success");
    } catch (_e) {
      setCallState("Failed to copy annotations");
    }
  };

  return (
    <div>
      <h3>Plan onboarding</h3>
      <div>{callStatus}</div>
      <button onClick={markComplete}>Mark complete</button>
      <div>
        <input placeholder="planQaId" onChange={(e) => setPlanQaId(e.target.value)} />
        <button onClick={copyAnnotations} disabled={!planQaId}>
          Copy annotations
        </button>
      </div>
    </div>
  );
};
