import { ApiInstance, apiInstance } from "./apiInstance";

export class BaseApi {
  private instance: ApiInstance;
  private baseUrl: string;
  private timeout: number;

  constructor(baseUrl: string, timeout: number = 60000 * 5) {
    this.baseUrl = baseUrl;
    this.instance = new ApiInstance();
    this.instance.create();
    this.timeout = timeout;
  }

  protected async get<T>(
    endPoint: string,
    authToken: string = localStorage.getItem("accessToken") as string,
    orgId: string = localStorage.getItem("orgId") as string,
    timeout = this.timeout,
    debugError = false,
  ): Promise<T> {
    const url = endPoint ? this.baseUrl + "/" + endPoint : this.baseUrl;
    const Authorization = authToken ? `Bearer ${authToken}` : undefined;
    try {
      const response = await apiInstance.instance.get(url, {
        withCredentials: true,
        headers: {
          Authorization,
          "x-org-id": orgId,
        },
        timeout,
      });
      return response.data;
    } catch (e) {
      if (debugError) {
        console.error(e);
      }
      throw new Error("Failed to request data from " + url);
    }
  }

  protected async putFile(signedUrl: string, file: File) {
    try {
      const data = new FormData();
      data.append("file", file);
      await fetch(signedUrl, {
        method: "PUT",
        body: data,
        headers: {
          "Content-Type": file.type,
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error("Failed to upload file with url " + signedUrl);
    }
  }

  protected async post<B, T>(
    endPoint: string,
    body: B,
    extraHeader: Record<string, string> = {},
    timeout = this.timeout,
  ): Promise<T> {
    const url = this.baseUrl + "/" + endPoint;
    try {
      const response = await apiInstance.instance.post(url, body, {
        headers: {
          ...extraHeader,
          Authorization: `Bearer ${localStorage.getItem("accessToken") as string}`,
          "x-org-id": localStorage.getItem("orgId") as string,
        },
        timeout: timeout,
        withCredentials: true,
      });
      return response.data;
    } catch (e) {
      console.error(e);
      throw new Error("Failed to request data from " + url);
    }
  }
}
