import React, { useRef } from "react";
import { ReviewResultType } from "@blueplan/types";
import { getReportResultClass, getReportResultText } from "@blueplan/core/src/report/reportUtil";
import "./finalAssessmentFilter.scss";
import { useRecoilState } from "recoil";
import { planReportFiltersAtom } from "../atom/PlanReportFiltersAtom";
import { Checkbox } from "../../checkbox/Checkbox";

export type ReviewResultTypeCategories = Record<ReviewResultType, boolean>;

const allMissingData = [
  ReviewResultType.NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS,
  ReviewResultType.NO_ENOUGH_DATA_MISSING_LOCATION_INFO,
  ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION,
  ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPONENTS,
  ReviewResultType.NO_ENOUGH_DATA_OTHER,
  ReviewResultType.NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO,
];

export const ReviewResultTypeFilter = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ reviewResultTypeFilter }, setPlanReportFilters] = useRecoilState(planReportFiltersAtom);

  const handleUpdate = (type: ReviewResultType, otherTypes: ReviewResultType[] = []) => {
    const state = !reviewResultTypeFilter[type];
    [type, ...otherTypes].forEach((value) =>
      setPlanReportFilters((old) => ({
        ...old,
        reviewResultTypeFilter: {
          ...old.reviewResultTypeFilter,
          [value]: state,
        },
      }))
    );
  };

  const renderItem = (
    value: ReviewResultType,
    otherValues: ReviewResultType[] = [],
    displayText: string | null = null
  ) => (
    <Checkbox
      handleClick={() => handleUpdate(value, otherValues)}
      value={displayText ?? getReportResultText(value)}
      classes={getReportResultClass(value)}
      checked={reviewResultTypeFilter[value]}
    />
  );

  return (
    <div className="final-assessment-filter" ref={ref}>
      {renderItem(ReviewResultType.COMPLIANCE)}
      {renderItem(ReviewResultType.NON_COMPLIANCE)}
      {renderItem(ReviewResultType.NO_ENOUGH_DATA_OTHER, allMissingData, "Missing data")}
      {renderItem(ReviewResultType.DEEPER_REVIEW)}
      {renderItem(ReviewResultType.NOT_APPLICABLE)}
      {renderItem(ReviewResultType.ON_SITE_VERIFICATION)}
    </div>
  );
};
