import React from "react";
import { CityName } from "@blueplan/types";
import { getCityDisplayName } from "../../util/addressUtil";

interface CityNameProps {
  name?: string;
}

export const CityNameDisplay = ({ name }: CityNameProps) => {
  return <span className="name-citation">{getCityDisplayName(name as CityName)}</span>;
};
