import { atom } from "recoil";
import { DataState, ServerAuthFinishedAtom } from "@blueplan/types";

export const serverAuthFinishedAtom = atom<ServerAuthFinishedAtom>({
  key: "auth",
  default: {
    state: DataState.NOT_LOADED,
    appLevelUser: undefined,
  },
});
