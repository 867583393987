import React, { useCallback, useMemo, ComponentType } from "react";
import { useRecoilState } from "recoil";
import { modalsAtom } from "@blueplan/core/src/atom/modelsAtom";
import { Modal } from "./Modal";

export interface ModalContentComponent<T = unknown> {
  handleClose: () => void;
  data?: T | null;
}

export const useModal = <T,>() => {
  const [ModalInnerComponent, setModalInnerComponent] =
    useRecoilState<React.ComponentType<ModalContentComponent<T>> | null>(
      modalsAtom,
    );

  const showModal = (
    Component: ComponentType<ModalContentComponent>,
    data?: T | null,
  ) => {
    const WrappedComponent = (props: ModalContentComponent<T>) => (
      <Component {...props} data={data} />
    );
    setModalInnerComponent(() => WrappedComponent);
  };

  const hideModal = useCallback(
    () => setModalInnerComponent(null),
    [setModalInnerComponent],
  );

  const modalComponent = useMemo(() => {
    if (!ModalInnerComponent) {
      return null;
    }

    return (
      <Modal handleBackdropClick={hideModal}>
        <ModalInnerComponent handleClose={hideModal} />
      </Modal>
    );
  }, [ModalInnerComponent, hideModal]);

  return {
    showModal,
    hideModal,
    isShown: !!ModalInnerComponent,
    modalComponent,
  };
};
