import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./appHeader.scss";
import { PageBehindAuth } from "@blueplan/core/src/auth/PageBehindAuth";
import { Debugger } from "../debugger/Debugger";
import { AppOutlet } from "../projects/AppOutlet";
import { useModal } from "@blueplan/core/src/uiComponents/useModal";

export const Page = () => {
  const location = useLocation();
  const { modalComponent } = useModal();

  useEffect(() => {
    document.getElementById("outlet")?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div id="page" className="page">
      <Debugger />
      <PageBehindAuth outlet={<AppOutlet />} modalComponent={modalComponent} />
    </div>
  );
};
