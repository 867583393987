import { MutableRefObject, useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { useBrowserEventListenerEvent } from "@blueplan/core/src/hook/useBrowserEventListenerEvent";
import { activeCanvasContextAtom } from "../projects/planManagment/PlanViewer";

export interface CanvasSizeInfo {
  dpi: number;
  scaleOverDpi: number;
  scale: number;
  originalImageWidth: number;
  originalImageHeight: number;
  zoom: number;
}

export const useCanvasSizeAndScale = (
  canvasRef: MutableRefObject<HTMLCanvasElement | null>
): CanvasSizeInfo => {
  const { pageSize, isReady } = useRecoilValue(activeCanvasContextAtom);
  const [{ scale, originalImageWidth, originalImageHeight, zoom }, setImageSize] =
    useRecoilState(pageImageSizeAtom);

  const dpi = window.devicePixelRatio || 1;
  const scaleOverDpi = scale / dpi;

  const handleContainerChange = useCallback(() => {
    if (!canvasRef.current || !canvasRef.current?.parentElement) {
      return;
    }

    const canvas = canvasRef.current;
    const parentEl = canvas.parentElement;
    if (!canvas || !parentEl || !isReady) {
      return;
    }

    const { width, height } = pageSize;
    const containerWidth = canvas.parentElement.getBoundingClientRect().width;
    const scale = containerWidth > width ? 1 : (containerWidth / width) * dpi;
    const adjustedContainerWidth = containerWidth;
    const adjustedContainerHeight = (height * scale) / dpi;
    const adjustedVideoHeight = Math.min(adjustedContainerHeight, height);
    const adjustedVideoWidth = Math.min(adjustedContainerWidth, width);

    canvas.style.width = `${adjustedContainerWidth}px`;
    canvas.style.height = `${adjustedContainerHeight}px`;

    canvas.width = adjustedContainerWidth * dpi;
    canvas.height = adjustedContainerHeight * dpi;

    setImageSize({
      containerWidth,
      scale,
      adjustedContainerWidth,
      adjustedContainerHeight,
      adjustedVideoWidth,
      adjustedVideoHeight,
      originalImageWidth: width,
      originalImageHeight: height,
      zoom,
      scaleOverDpi,
      dpi,
    });
  }, [setImageSize, canvasRef, pageSize, dpi, zoom, scaleOverDpi, isReady]);

  useEffect(() => {
    handleContainerChange();
  }, [handleContainerChange]);

  useBrowserEventListenerEvent("resize", handleContainerChange);

  return {
    dpi,
    scaleOverDpi,
    scale,
    originalImageWidth,
    originalImageHeight,
    zoom,
  };
};
