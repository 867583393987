import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { isProjectReady } from "../../../util/addressUtil";
import { useRecoilValue } from "recoil";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { Button, ButtonColor, ButtonSize } from "@blueplan/core/src/theme/Button";
import { ProjectModel } from "@blueplan/types";
import { DebouncedInput } from "../../other/DebounceInput";
import "./projectsPage.scss";
import { serverAuthFinishedAtom } from "@blueplan/core/src/auth/serverAuthFinishedAtom";
import { GoPlus } from "react-icons/go";
import { useModal } from "@blueplan/core/src/uiComponents/useModal";
import { CreateProject } from "./CreateProject";
import { ProjectsList } from "@blueplan/core/src/project/ProjectsList";

export const ProjectsPage = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const { showModal } = useModal();

  const onRowClick = useCallback(
    (project: ProjectModel) => {
      if (!isProjectReady(project.address)) {
        return;
      }

      navigate(
        "/" +
          generatePath(ROUTER_PATHS.PROJECT_SETTINGS, {
            projectId: project.projectId,
          })
      );
    },
    [navigate]
  );

  const projectsList = (
    <div className="projects-list">
      <div className="actions flex">
        <div className="search flex">
          <DebouncedInput
            value={globalFilter}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder="Search address"
          />
        </div>
        <Button
          size={ButtonSize.SMALL}
          color={ButtonColor.BRAND}
          onClick={() => showModal(CreateProject)}
          IconLeft={GoPlus}
          classes="create-project"
        >
          Create
        </Button>
      </div>
      <ProjectsList globalFilter={globalFilter} onRowClick={onRowClick} />
    </div>
  );

  return (
    <div className="projects">
      <h3>Welcome, {appLevelUser?.name}</h3>
      <div className="welcome flex">
        <img className="logo" src="/logo.png" />
        <div>
          <h4>Get started with Blueplan</h4>
          <div>
            To set up your account, create the first <br /> project and your first plan to Blueplan
            AI.
          </div>
        </div>
      </div>
      {projectsList}
    </div>
  );
};
