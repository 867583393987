import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import { RecoilRoot } from "recoil";
import Modal from "react-modal";
import { apiInstance } from "@blueplan/core/src/api/apiInstance";
import "./antOverride.scss";
import "./app.scss";
import { Auth0Provider } from "@auth0/auth0-react";
import { ROUTER_PATHS } from "./routerPaths";
// import { mouseflow } from "react-mouseflow";
import { ErrorBoundary } from "./ErrorBoundary";

Modal.setAppElement("#root");
apiInstance.create();

const App = () => {
  useEffect(() => {
    // mouseflow.initialize("4bd111f4-df85-4575-80d7-1379ec31b4d7\n");
  }, []);

  return (
    <div className="app">
      <ErrorBoundary>
        <RecoilRoot>
          <Auth0Provider
            domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
            clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
            authorizationParams={{
              redirect_uri: window.location.origin + "/" + ROUTER_PATHS.ROOT,
            }}
          >
            <RouterProvider router={router} />
          </Auth0Provider>
        </RecoilRoot>
      </ErrorBoundary>
    </div>
  );
};

export default App;
