import { PlanQA } from "@blueplan/types";
import { createDataAtom, useData } from "@blueplan/core/src/atom/AtomDataClass";

export const currenPlanQaAtom = createDataAtom<PlanQA>("currentPlanQa");

export const useCurrenPlanQaData = () => useData<PlanQA>(currenPlanQaAtom);

export const planQaListAtom = createDataAtom<PlanQA[]>("planQaList");

export const usePlanQaListData = () => useData<PlanQA[]>(planQaListAtom);
