import React, { useCallback, useMemo, useState } from "react";
import "./adminPlanViewer.scss";
import { PlanQAUI } from "./PlanQAUI";
import { AdminProcessPlanTemp } from "./AdminProcessPlanTemp";
import { onboardingApi } from "@blueplan/core/src/api/onboardingApi";

export const AdminPlanViewer = () => {
  const [searchPlanId, setSearchPlanId] = useState(
    localStorage.getItem("admin-dataOfTypeX-id-search") ?? ""
  );
  const [dataOfTypeX, setDataOfTypeX] = useState<unknown | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dataType, setDataType] = useState("");

  const genericGetter = useCallback(
    async (api: unknown, type: string) => {
      setIsLoading(true);
      setDataOfTypeX(null);
      setIsError(false);

      try {
        const plan = await api();
        setDataOfTypeX(plan);
        setIsError(false);
        setDataType(type);
        localStorage.setItem("admin-dataOfTypeX-id-search", searchPlanId);
        const searchHistory = JSON.parse(
          localStorage.getItem("admin-dataOfTypeX-id-search-history") ?? "[]"
        ) as { id: string; type: string }[];
        searchHistory.push({ id: searchPlanId, type });
        localStorage.setItem("admin-dataOfTypeX-id-search-history", JSON.stringify(searchHistory));
      } catch (e) {
        console.error(
          "Failed to make a request. Can be many reasons like API or local storage, see following:"
        );
        console.error(e);
        setIsError(true);
        setDataType("");
      }

      setIsLoading(false);
    },
    [searchPlanId, setDataOfTypeX, setIsError]
  );

  const searchPlanById = useCallback(async () => {
    genericGetter(() => onboardingApi.planPages(searchPlanId), "plan");
  }, [searchPlanId, genericGetter]);

  const searchPlanQaId = useCallback(async () => {
    genericGetter(() => onboardingApi.getPlanQa(searchPlanId), "planQa");
  }, [searchPlanId, genericGetter]);

  const planQaUi = useMemo(() => {
    if (dataType !== "planQa") {
      return null;
    }

    return <PlanQAUI planQA={dataOfTypeX} />;
  }, [dataOfTypeX, dataType]);

  const getSearchHistory = () => {
    const history = localStorage.getItem("admin-dataOfTypeX-id-search-history") || "[]";
    try {
      return JSON.parse(history).slice(0, 5);
    } catch (_e) {
      return [];
    }
  };

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div className="admin-plan-viewer">
      <h3>Search history (last 5):</h3>
      <div>
        {getSearchHistory().map((item: { id: string; type: string }, index: number) => (
          <div key={index}>
            {item.id} | {item.type} |{" "}
            <div
              onClick={() => {
                setSearchPlanId(item.id);
                setDataType("");
              }}
            >
              Load
            </div>
          </div>
        ))}
      </div>
      <div className="search">
        <h3>Search</h3>
        {isError && <div>Error loading plan</div>}
        <input onChange={(e) => setSearchPlanId(e.target.value)} value={searchPlanId} />
        {dataType}
        <div>
          <button onClick={searchPlanById} disabled={isLoading || !searchPlanId}>
            Search Plan
          </button>
          <button onClick={searchPlanQaId} disabled={isLoading || !searchPlanId}>
            Search PlanQa
          </button>
        </div>
      </div>
      {dataType === "plan" && <AdminProcessPlanTemp planId={searchPlanId} />}
      {dataOfTypeX && <h3>Data</h3>}
      {planQaUi}
    </div>
  );
};
