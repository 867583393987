import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { currentProjectsAtom } from "@blueplan/core/src/atom/currentProjectAtom";
import { projectApi } from "@blueplan/core/src/api/projectApi";
import { assertDefined } from "@blueplan/core/src/util/assertions";
import { DataLoadingStates, PlanModel } from "@blueplan/types";

export const useActivePlan = () => {
  const [currentProject, setCurrentProject] = useRecoilState(currentProjectsAtom);

  const getPlan = useCallback(
    (planId: string) => currentProject.plans.find((plan) => plan.id === planId),
    [currentProject]
  );

  const activePlan = useMemo(
    () => (currentProject.model ? getPlan(currentProject.model?.activePlanId) : null),
    [currentProject.model, getPlan]
  );

  const setActivePlan = useCallback(
    (plan: PlanModel) => {
      projectApi.updateActivePlan(assertDefined(currentProject.model?.projectId), plan.id);
      setCurrentProject((old) => {
        if (!old.model) {
          return old;
        }
        return { ...old, model: { ...old.model, activePlanId: plan.id } };
      });
    },
    [currentProject.model, setCurrentProject]
  );

  const isProcessingPlan = useMemo(
    () => activePlan?.status !== DataLoadingStates.COMPLETED,
    [activePlan]
  );

  const getDefinedActivePlan = useCallback(() => assertDefined(activePlan), [activePlan]);

  return {
    activePlan,
    setActivePlan,
    isProcessingPlan,
    getDefinedActivePlan,
  };
};
