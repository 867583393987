/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2025-03-19 16:06:25.

export interface Diagnostic {
    llmCallDiagnostic?: LlmCallDiagnostic;
    planId?: string;
    planQaId?: string;
    projectId?: string;
    reportId?: string;
    codeYear?: number;
    city?: string;
    fullSectionPath?: string;
    updateComponentManually?: UpdateComponentManually;
    diagnosticId: string;
    resourceOwner?: ResourceOwner;
    created: Date;
    session: string;
    type: DiagnosticType;
    reporter: DiagnosticReporter;
    booksMetadata?: BookMetadata[];
    rerunOfSomething?: boolean;
}

export interface DiagnosticReporter {
    name: string;
    version: string;
}

export interface LlmCallDiagnostic {
    systemPrompt: string;
    basePrompt: string;
    additionalMessages: string[];
    imagesUrls: string[];
    result: string;
    model: string;
    topP: number;
    maxTokens: number;
    error: string;
}

export interface UpdateComponentManually {
    componentId: string;
    planId: string;
    pageIndex: number;
    before: ComponentData;
    after: ComponentData;
}

export interface AskQuestionRequest {
    planQaId: string;
    question: string;
    booksMetadataIds: string[];
}

export interface BlobStorage {
    bucket: string;
    id: string;
    md5: string;
}

export interface BookDefinition extends BookMetadata {
    items: BuildingCodeRecord[];
}

export interface BookRef extends PartialBookRef {
    bookType: BookType;
}

export interface BuildingCodeBookRef {
}

export interface CreatePlanQaRequest {
    planId: string;
    jurisdiction?: Jurisdiction;
}

export interface DataSource {
    address: Address;
    bin: number;
    lastFetched?: Date;
    status: DataSourceType;
    dataUrl: string;
}

export interface FullPlanReport extends PlanReport {
    sectionComplianceReportList: SectionComplianceReport[];
}

export interface Images {
    image: BlobStorage;
    thumbnail: BlobStorage;
}

export interface OktaUserInfo {
    oktaOrg?: Organization;
    oktaUserId: string;
    email: string;
    roles: string[];
    intercomHash: string;
    values: { [index: string]: any };
}

export interface PartialBookRef {
    type: Type;
    year: number;
}

export interface PlanAnnotation {
    id: string;
    failed: boolean;
    planQaId: string;
    label: string;
    rect: Rect;
    pageNumber: number;
    image?: BlobStorage;
    category: string;
    ready: boolean;
    deleted: boolean;
}

export interface PlanQARequest {
    planQaId: string;
    category: string;
    pageNumber: number;
    rect: Rect;
}

export interface ProjectAndPlan {
    projectModel: ProjectModel;
    planModel: PlanModel;
}

export interface ReportPreset {
    name: string;
    id: string;
    items: Item[];
}

export interface Item {
    section: string;
    bookId: string;
}

export interface SignedUrlResponse {
    signedUrl: string;
    id: string;
}

export interface Rect {
    start: XYPoint;
    end: XYPoint;
}

export interface XYPoint {
    x: number;
    y: number;
}

export interface CopyAnnotationRequest {
    planId: string;
    planQaForAnnotationId: string;
    resourceOwner: ResourceOwner;
}

export interface ExtractSheetDetailsRequest {
    pageImageUrl: string;
}

export interface GetParentComponentsImageRequest {
    parentComponentId: string;
    save: boolean;
}

export interface InitialPlanOnboardingRequest {
    planId: string;
    pdfBlob: BlobStorage;
    resourceOwner: ResourceOwner;
}

export interface SaveSubComponentsRequest {
    planId: string;
    pageIndex: number;
    parentComponentId: string;
    componentDataList: ComponentData[];
    componentRect: Rect[];
}

export interface ComponentData {
    title: string;
    description: string;
    extractedData: string;
    categories: string[];
}

export interface FindComponentsResponse {
    component: Component;
    children: Component[];
}

export interface PageComponentTextualContextRequest {
    componentImageUrl: string;
    waitForResponse: boolean;
}

export interface PageComponentTextualContextResponse {
    baseComponent: ComponentData;
}

export interface PlanQuestionRequest {
    planId: string;
    planQaId: string;
    questionId: string;
    imageUrls: string[];
    userQuestion: string;
    booksMetadata: BookMetadata[];
    creator: ResourceOwner;
}

export interface PlanTextualContextRequest {
    pageImageUrls: string[];
}

export interface PlanTextualContextResponse {
    answer: string;
}

export interface PythonCallWithProjectAndPlan<T> {
    project?: ProjectModel;
    plan?: PlanModel;
    data?: T;
    diagnostic?: Diagnostic;
}

export interface SectionComplianceRequest {
    sections: BuildingCodeRecord[];
    reportId: string;
    resourceOwner: ResourceOwner;
    diagnostic: Diagnostic;
}

export interface SegmentPageRequest {
    imageUrl: string;
}

export interface SegmentationBoxes {
    bbox: number[];
    pointsPath: number[][];
    predictionScore: number;
    boxId: string;
}

export interface SegmentationBoxesResponse {
    boxes: SegmentationBoxes[];
    id: string;
}

export interface SubComponentTextualContextRequest {
    parentComponentImageUrl: string;
}

export interface SubComponentTextualContextResponse {
    componentRect: Rect[];
    subComponents: ComponentData[];
}

export interface AllBinStatus {
    dobBuildingData: DataSource;
    bisBuildingData: DataSource;
    bisActionData: DataSource;
    bisZoningData: DataSource;
}

export interface BisZoningDataUI {
    filingDataString: string;
    job: ValueWithLink<number>;
    jobType: string;
    jobStatus: string;
    statusDataString: string;
    document: ValueWithLink<string>;
}

export interface GizGetZoningResponse extends DataSource {
    location: any;
    zoningPolygons: ZoningPolygon[];
}

export interface ValueWithLink<T> {
    value: T;
    link?: string;
}

export interface ZoningDocuments {
    name: string;
    formId: string;
    docNumber: number;
    dateScanned: string;
    scanCode: string;
}

export interface CollectComplianceReports {
    reportMap: { [index: string]: SectionComplianceReport };
    buildingCodeMap: { [index: string]: BuildingCodeRecord };
}

export interface PlanReportRequest {
    planId: string;
    cityName: CityName;
    presetId: string;
}

export interface SectionComplianceReasoning {
    requirement: string;
    providedData: string;
    analysis: string;
    evaluation: string;
    sources: string[];
}

export interface SectionComplianceReport {
    sectionComplianceId: string;
    item: string;
    reportId: string;
    analysis: string;
    analysisSummary: string;
    finalAssessment: ReviewResultType;
    buildingCode: BuildingCodeRecord;
    created: Date;
    markedBlueprintLocation: ComponentReference[];
    active: boolean;
    error: boolean;
    rerun: boolean;
}

export interface BookMetadata {
    id: string;
    groupId: string;
    cityName: CityName;
    type: BookType;
    displayName: string;
    year: number;
}

export interface BuildingCodeRecord {
    id: string;
    key: string;
    chapter: string;
    fullSectionPath: string;
    topSection: string;
    title: string;
    text: string;
    year: number;
    city: CityName;
    type: BookType;
    bookId: string;
}

export interface ShortBuildingCodeRecord {
    bookId: string;
    sectionPath: string;
}

export interface BuildingCodeReference {
    year: number;
    city: CityName;
    fullSectionPath: string;
}

export interface OpenAIRequest {
    id: string;
    date: Date;
    tokens: number;
    model: string;
}

export interface Address {
    id: string;
    number: number;
    street: string;
    newYorkDetails?: NewYorkDetails;
    philadelphiaDetails?: PhiladelphiaDetails;
    city: CityName;
    realCityName: string;
    state: string;
    zip: number;
    googlePlaceId: string;
    formatted: string;
}

export interface LocalDetails {
    nyBorough: NyBorough;
    philadelphia: boolean;
    ny: boolean;
}

export interface NewYorkDetails {
    nyBorough: NyBorough;
    bin?: number;
}

export interface PhiladelphiaDetails {
    nyBorough: NyBorough;
    bin: number;
}

export interface PlanModel {
    id: string;
    created: Date;
    updated: Date;
    projectId: string;
    pages: PlanPage[];
    status: DataLoadingStates;
    title: string;
    planTextualContext?: string;
    originalFile: BlobStorage;
}

export interface PlanPage {
    id: string;
    created: Date;
    updated: Date;
    planId: string;
    pageNumber: number;
    components: Component[];
    pageImageBlob: BlobStorage;
    thumbnailBlob: BlobStorage;
    sheetDetails: SheetDetails;
}

export interface Component {
    componentId: string;
    imageBlobStorage: BlobStorage;
    rect: Rect;
    data: ComponentData;
    parentComponentId?: string;
    pageIndex?: number;
}

export interface ComponentReference {
    pageNumber: number;
    componentId: string;
}

export interface SheetDetails {
    sheetNumber: string;
    sheetTitle: string;
}

export interface PlanQA {
    planQaId: string;
    planId: string;
    title: string;
    created: Date;
    planAnnotation: { [index: string]: PlanAnnotation };
}

export interface PlanQuestion {
    booksMetadata: BookMetadata[];
    id: string;
    inquiryId: string;
    question: string;
    answer?: Answer;
    created: Date;
    status: DataLoadingStates;
}

export interface Analysis {
    requirement: string;
    analysis: string;
    evaluation: string;
    sources: string[];
}

export interface Answer {
    answer: string;
    analysis: string;
    analysisSummary: string;
    markedBlueprintLocation: ComponentReference[];
    relevantSections: ShortBuildingCodeRecord[];
}

export interface ProjectModel {
    activePlanId: string;
    address: Address;
    googlePlacesId: string;
    created: Date;
    projectId: string;
    orgId: string;
    creator: string;
    jurisdiction: Jurisdiction;
    projectSize: ProjectSize;
    projectType: string;
    scopes: string[];
    freeformScope: string;
    buildingType: string;
    buildingSize: number;
    waitingCityOnboarding: boolean;
}

export interface Jurisdiction {
    cityName: CityName;
    codeYear: number;
}

export interface GeneralPrompt {
    promptKey: string;
    created: Date;
    updated: Date;
    promptId: string;
    head: PromptRecord;
    history: PromptRecord[];
}

export interface PromptRecord {
    recordId: string;
    created: Date;
    text: string;
}

export interface PlanReport {
    reportId: string;
    created: Date;
    status: DataLoadingStates;
    title: string;
    presetId: string;
    planId: string;
}

export interface BisActionData extends DataSource {
    jsonAsStringData: string;
}

export interface BisBuildingData extends DataSource {
    jsonAsStringData: string;
}

export interface BisStopWorkData extends DataSource {
    items: StopWorkItem[];
}

export interface StopWorkItem {
    complaintNumber: number;
    address: string;
    dateEntered: string;
    dateInspection: string;
    category: string;
    disposition: string;
    status: string;
}

export interface BisZoningData extends DataSource {
    documents: { [index: string]: ZoningDocuments[] };
    zoning: BisZoningDataUI[];
}

export interface DobBuildingData extends DataSource {
    jsonAsStringData: string;
}

export interface ZoningPolygon {
    id: string;
    zone: string;
    zoneName?: string;
    dataType: string;
    polygon: any;
    specialPurpose?: SpecialPurpose;
}

export interface SpecialPurpose {
    name: string;
    subDistrict: string;
    subDistrictLabel: string;
    subAreaOther: string;
    subArea: string;
    subAreaName: string;
}

export interface ResourceKey {
    resourceId: string;
    owner: ResourceOwner;
}

export interface ResourceOwner {
    orgId: string;
    userId: string;
    uberRole?: boolean;
}

export interface IdUtil {
}

export interface RandomStupidNameGenerator {
}

export interface Organization {
    name: string;
    id: string;
    display_name: string;
    metadata: { [index: string]: any };
    branding: Branding;
    /**
     * @deprecated
     */
    enabled_connections: EnabledConnection[];
}

export interface Branding {
    logo_url: string;
    colors: Colors;
}

export interface EnabledConnection {
    connection_id: string;
    connection: Connection;
    assign_membership_on_login: boolean;
    show_as_button: boolean;
}

export interface Colors {
    primary: string;
    page_background: string;
}

export interface Connection {
    name: string;
    strategy: string;
}

export enum DiagnosticType {
    PLAN_QA = "PLAN_QA",
    PLAN_REPORT = "PLAN_REPORT",
    SERVER_START = "SERVER_START",
    UPDATE_COMPONENT = "UPDATE_COMPONENT",
    EXTRACT_SHEET_DETAILS = "EXTRACT_SHEET_DETAILS",
    ROOM_SUB_COMPONENTS = "ROOM_SUB_COMPONENTS",
}

/**
 * Values:
 * - `MANHATTAN`
 * - `BROOKLYN`
 * - `BRONX`
 * - `QUEENS`
 * - `STATEN_ISLAND`
 * - `NEW_YORK_CITY`
 * - `PHILADELPHIA`
 * - `NEW_JERSEY`
 * - `CALIFORNIA`
 * - `NOT_DETERMINED`
 * - `PARAMUS` - @deprecated
 */
export enum CityName {
    MANHATTAN = "MANHATTAN",
    BROOKLYN = "BROOKLYN",
    BRONX = "BRONX",
    QUEENS = "QUEENS",
    STATEN_ISLAND = "STATEN_ISLAND",
    NEW_YORK_CITY = "NEW_YORK_CITY",
    PHILADELPHIA = "PHILADELPHIA",
    NEW_JERSEY = "NEW_JERSEY",
    CALIFORNIA = "CALIFORNIA",
    NOT_DETERMINED = "NOT_DETERMINED",
    /**
     * @deprecated
     */
    PARAMUS = "PARAMUS",
}

export enum DataLoadingStates {
    INITIAL_PROCESSING = "INITIAL_PROCESSING",
    COMPLETED = "COMPLETED",
    PENDING_DATA_EXTRACTION = "PENDING_DATA_EXTRACTION",
    ERROR = "ERROR",
}

export enum NyBorough {
    BROOKLYN = "BROOKLYN",
    MANHATTAN = "MANHATTAN",
    QUEENS = "QUEENS",
    BRONX = "BRONX",
    ISLAND = "ISLAND",
    NOT_NY = "NOT_NY",
}

export enum Type {
    CUSTOM = "CUSTOM",
}

export enum DataSourceType {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE",
    FAILED = "FAILED",
}

export enum ReviewResultType {
    COMPLIANCE = "COMPLIANCE",
    NON_COMPLIANCE = "NON_COMPLIANCE",
    NOT_APPLICABLE = "NOT_APPLICABLE",
    ON_SITE_VERIFICATION = "ON_SITE_VERIFICATION",
    NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS = "NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS",
    NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO = "NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO",
    NO_ENOUGH_DATA_MISSING_LOCATION_INFO = "NO_ENOUGH_DATA_MISSING_LOCATION_INFO",
    NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION = "NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION",
    NO_ENOUGH_DATA_MISSING_COMPONENTS = "NO_ENOUGH_DATA_MISSING_COMPONENTS",
    NO_ENOUGH_DATA_OTHER = "NO_ENOUGH_DATA_OTHER",
    DEEPER_REVIEW = "DEEPER_REVIEW",
}

export enum BookType {
    BUILDING_CODE = "BUILDING_CODE",
    ACCESSIBILITY = "ACCESSIBILITY",
}

export enum ProjectSize {
    _1000 = "_1000",
    _5000 = "_5000",
    _10000 = "_10000",
    _20000 = "_20000",
    _50000 = "_50000",
    _100000 = "_100000",
}
