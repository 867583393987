import React, { useMemo } from "react";
import { MultiLinearLoader } from "@blueplan/webapp/src/components/other/MultiLinearLoader";
import { Table } from "@blueplan/webapp/src/components/theme/Table";
import { isProjectReady } from "@blueplan/webapp/src/util/addressUtil";
import { EmptyContainer } from "@blueplan/webapp/src/components/other/EmptyContainer";
import { useRecoilValue } from "recoil";
import { projectsAtom } from "../atom/projectsAtom";
import { Address, DataState, ProjectModel } from "@blueplan/types";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ResourceAvatar } from "@blueplan/webapp/src/components/other/ResourceAvatar";
import { generatePath, Link } from "react-router-dom";
import { ROUTER_PATHS } from "@blueplan/webapp/src/components/app/routerPaths";
import classNames from "classnames";
import { FaQuestion } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "./projectsList.scss";

export const AddressView = ({
  address,
  projectId,
}: {
  address: Address;
  projectId: string;
}) => {
  if (isProjectReady(address)) {
    return (
      <Link
        to={generatePath(ROUTER_PATHS.PROJECT_SETTINGS, {
          projectId: projectId,
        })}
      >
        {address.formatted}
      </Link>
    );
  }
  return <div> {address.formatted}</div>;
};

const CityIdView = ({ address }: { address: Address }) => {
  const cityName = address.realCityName;
  const loading = !isProjectReady(address);
  const classes = classNames("city-name-view", { loading });
  const text = loading ? (
    <>
      <span>Onboarding</span>
      <FaQuestion />
    </>
  ) : (
    cityName
  );
  return (
    <div className={classes}>
      <Tooltip id={address.formatted} place="top-start">
        We are onboarding {cityName}, we will email you when the project is
        ready to view
      </Tooltip>
      <span
        data-tooltip-id={loading ? address.formatted : ""}
        className="inner"
      >
        {text}
      </span>
    </div>
  );
};

const columnHelper = createColumnHelper<ProjectModel>();

const columns = [
  columnHelper.accessor("address", {
    header: () => <span>Address</span>,
    cell: (info) => (
      <AddressView
        address={info.row.original.address}
        projectId={info.row.id}
      />
    ),
  }),
  columnHelper.accessor("jurisdiction", {
    header: () => <span>Jurisdiction</span>,
    cell: (info) => <CityIdView address={info.row.original.address} />,
  }),
  columnHelper.accessor("created", {
    header: () => <span>Created</span>,
    cell: (info) => new Date(info.getValue()).toDateString(),
  }),
  columnHelper.accessor("projectId", {
    header: () => <span></span>,
    cell: (info) => <ResourceAvatar resourceId={info.getValue()} />,
    meta: { additionalClass: "avatar" },
  }),
];

interface ProjectsListProps {
  globalFilter: string;
  onRowClick: (project: ProjectModel) => void;
}

export const ProjectsList = ({
  globalFilter,
  onRowClick,
}: ProjectsListProps) => {
  const projectsStore = useRecoilValue(projectsAtom);
  const filteredList = useMemo(
    () =>
      projectsStore.list.filter((p) =>
        p.address.formatted.toLowerCase().includes(globalFilter),
      ),
    [projectsStore.list, globalFilter],
  );
  const table = useReactTable({
    data: filteredList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="projects-list">
      {projectsStore.state === DataState.LOADING && <MultiLinearLoader />}
      {projectsStore.state === DataState.LOADED && projectsStore.list.length ? (
        <Table<ProjectModel>
          table={table}
          onRowClick={onRowClick}
          classes="list"
          getRowClass={(project: ProjectModel) =>
            isProjectReady(project.address) ? "" : "disabled"
          }
        />
      ) : (
        <EmptyContainer>No projects</EmptyContainer>
      )}
    </div>
  );
};
