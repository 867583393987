import React from "react";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "@blueplan/core/src/auth/serverAuthFinishedAtom";
import "./debugger.scss";
import { FaQuestion } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { Link, useParams } from "react-router-dom";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { Button, ButtonColor } from "@blueplan/core/src/theme/Button";
import { FaCopy } from "react-icons/fa6";

const DEBUGGER_ROLE = "debugger-blueplan";

export const Debugger = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  if (!appLevelUser?.roles?.includes(DEBUGGER_ROLE)) {
    return null;
  }

  return <DebuggerInner />;
};

const DebuggerInner = () => {
  return (
    <div className="debugger" data-tooltip-id="debugger">
      <DebuggerContent />
      <div className="placeholder">
        <FaQuestion />
      </div>
    </div>
  );
};
const keyValue = (key: string, value: string | undefined) => {
  if (!value) {
    return null;
  }

  return (
    <div className="item">
      <div className="key">{key}</div>
      <div className="value">
        <div>{value}</div>
        <Button
          color={ButtonColor.ICON}
          IconLeft={FaCopy}
          onClick={() => navigator.clipboard.writeText(value ?? "")}
        />
      </div>
    </div>
  );
};

const DebuggerContent = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  const { projectId, planQaId, planId, reportId } = useParams();
  const { activePlan } = useActivePlan();
  const backOfficeUrl = import.meta.env.VITE_BACK_OFFICE_URL;

  const currentPlanId = activePlan?.id ?? planId;

  return (
    <Tooltip id="debugger" openOnClick clickable>
      <div>
        <h4>Admin</h4>
        <ul>
          <li>
            <a href={`${backOfficeUrl}/projects`} target="_blank" rel="noreferrer">
              Projects
            </a>
          </li>
          {projectId && (
            <li>
              <a href={`${backOfficeUrl}/project/${projectId}`} target="_blank" rel="noreferrer">
                Visit project
              </a>
            </li>
          )}
          {projectId && currentPlanId && (
            <li>
              <a
                href={`${backOfficeUrl}/project/${projectId}/${currentPlanId}`}
                target="_blank"
                rel="noreferrer"
              >
                Visit plan
              </a>
            </li>
          )}
          {projectId && currentPlanId && reportId && (
            <li>
              <a
                href={`${backOfficeUrl}/project/${projectId}/${currentPlanId}/${reportId}`}
                target="_blank"
                rel="noreferrer"
              >
                Visit report
              </a>
            </li>
          )}
          <li>
            <Link to="/admin/markdown">Markdown</Link>
          </li>
        </ul>
      </div>
      <div>
        <h4>IDs</h4>
        {keyValue("Project Id", projectId)}
        {keyValue("Plan Id", activePlan?.id)}
        {keyValue("Plan QA Id", planQaId)}
        {keyValue("Report Id", reportId)}
        {keyValue("User id", appLevelUser?.oktaUserId)}
        {keyValue("Roles", appLevelUser?.roles.join(", "))}
      </div>
    </Tooltip>
  );
};
