import { useCallback } from "react";
import {
  SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT,
  ShowPageInPageView,
} from "../../markdown/PageRefLink";
import { useBrowserEventListenerEvent } from "@blueplan/core/src/hook/useBrowserEventListenerEvent";

export const useHighlightedPageAndComponentListener = (setPage: (pageIndex: number) => void) => {
  const handleShowPage = useCallback(
    (event: CustomEvent<ShowPageInPageView>) => {
      const { pageIndex } = event.detail as ShowPageInPageView;
      setPage(parseInt(pageIndex) - 1);
    },
    [setPage]
  );

  useBrowserEventListenerEvent(SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT, handleShowPage);
};
