import React, { useMemo } from "react";
import { ReviewResultType } from "@blueplan/types";
import { SectionComplianceReportCard } from "../SectionComplianceReportCard";
import {
  getReportResultClassForStr,
  getReportResultText,
} from "@blueplan/core/src/report/reportUtil";
import { ReportResultFilter } from "../reportSectionFilter/ReportResultFilter";
import "./reportListJourney.scss";
import { useRecoilValue } from "recoil";
import { planReportFiltersAtom } from "../atom/PlanReportFiltersAtom";
import { groupedFilteredSectionComplianceReport } from "../atom/filteredPlanReportSectionsAtom";
import classNames from "classnames";
import { activeCanvasContextAtom } from "../../projects/planManagment/PlanViewer";

export const ReportListJourney = () => {
  const { reviewResultTypeFilter, filterByPage } = useRecoilValue(planReportFiltersAtom);
  const groupedSections = useRecoilValue(groupedFilteredSectionComplianceReport);
  const { pageIndex } = useRecoilValue(activeCanvasContextAtom);

  const allSections = useMemo(() => {
    const sectionsItems = Object.entries(groupedSections)
      .filter(([category, _]) => reviewResultTypeFilter[category as ReviewResultType])
      .map(([key, items]) => {
        const cards = items
          // If the `filterByPage` value is true, show only items that are relevant to the current page.
          .filter(
            (item) =>
              !filterByPage ||
              !!item.markedBlueprintLocation.find(
                (location) => location.pageNumber === pageIndex + 1
              )
          )
          .map((item, key) => <SectionComplianceReportCard item={item} key={key} />);
        if (cards.length === 0) {
          return null;
        }

        return (
          <div className="group" key={key}>
            <div className="header flex">
              {getReportResultText(key)}
              <div className={classNames("number", getReportResultClassForStr(key))}>
                {cards.length} items
              </div>
            </div>
            <div className="section flex">{cards}</div>
          </div>
        );
      })
      .filter((group) => !!group);

    if (sectionsItems.length === 0) {
      return <div className="empty flex">No section found, update filters.</div>;
    }

    return sectionsItems;
  }, [groupedSections, reviewResultTypeFilter, filterByPage, pageIndex]);

  return (
    <div className="report-list-journey flex">
      <ReportResultFilter />
      <div className="results flex">{allSections}</div>
    </div>
  );
};
