import { ReviewResultType } from "@blueplan/types";
import "./complianceReport.module.scss";

export const getReportResultClass = (result: ReviewResultType): string =>
  getReportResultClassForStr(result.toString());

export const getReportResultClassForStr = (result: string): string => {
  switch (result) {
    case ReviewResultType.DEEPER_REVIEW:
      return "report-deeper-review";
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS:
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION:
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO:
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_LOCATION_INFO:
    case ReviewResultType.NO_ENOUGH_DATA_OTHER:
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPONENTS:
      return "report-not-enough-data";
    case ReviewResultType.COMPLIANCE:
      return "report-compliance";
    case ReviewResultType.NON_COMPLIANCE:
      return "report-non-compliance";
    case ReviewResultType.ON_SITE_VERIFICATION:
      return "report-on-site-verification";
    case ReviewResultType.NOT_APPLICABLE:
      return "report-not-applicable";
    default:
      throw Error("No class for " + result);
  }
};

export const getReportResultText = (result: string) => {
  switch (result) {
    case ReviewResultType.DEEPER_REVIEW:
      return "Deeper review";
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_MEASUREMENT_OR_CALCULATIONS:
      return "Missing measurements";
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPLIANCE_DOCUMENTATION:
      return "Missing compliance documentation";
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_INSTALLATION_OR_CONSTRUCTION_INFO:
      return "Missing installation info";
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_LOCATION_INFO:
      return "Missing location";
    case ReviewResultType.NO_ENOUGH_DATA_OTHER:
      return "Missing data";
    case ReviewResultType.NO_ENOUGH_DATA_MISSING_COMPONENTS:
      return "Missing component";
    case ReviewResultType.COMPLIANCE:
      return "Compliance";
    case ReviewResultType.NON_COMPLIANCE:
      return "Non compliance";
    case ReviewResultType.ON_SITE_VERIFICATION:
      return "One site verification";
    case ReviewResultType.NOT_APPLICABLE:
      return "Not applicable";
  }
};
