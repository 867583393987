import { Component } from "@blueplan/types";
import { useFullPlanData } from "@blueplan/core/src/atom/fullPlanAtom";

export const useAllPlanComponents = (): Record<string, Component> => {
  const fullPlanData = useFullPlanData();

  if (fullPlanData.isNotDone()) {
    return {};
  }

  // Unfortunately, the component does not have the page number, so we are adding it here.
  return [
    ...fullPlanData
      .data()
      .pages.map((page) => [...page.components.map((c) => ({ ...c, pageIndex: page.pageNumber }))]),
  ]
    .flat(2)
    .reduce((acc, c) => ({ ...acc, [c.componentId]: c }), {});
};
