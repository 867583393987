import { Rect, XYPoint } from "@blueplan/types";

import { AnnotationTheme, CanvasAnnotation } from "./canvasAnnotationUtil";

export const findFirstRectForPoint = (
  annotations: CanvasAnnotation[],
  point: XYPoint,
  pageNumber: number
) => {
  const filteredAnnotations = annotations.filter(
    (annotation) => annotation.pageNumber === pageNumber
  );
  const { x, y } = point;
  let index = 0;
  for (const annotation of filteredAnnotations) {
    const { rect } = annotation;

    if (x >= rect.start.x && x <= rect.end.x && y >= rect.start.y && y <= rect.end.y) {
      return { key: annotation.componentId, index, annotation, rect };
    }
    index++;
  }

  return null;
};
export const scaleUpRect = (rect: Rect, scale: number): Rect => {
  return {
    start: scaleUpPoint(rect.start, scale),
    end: scaleUpPoint(rect.end, scale),
  };
};

export const scaleUpPoint = (point: XYPoint, scale: number): XYPoint => {
  const invertScale = 1 / scale;
  return { x: point.x * invertScale, y: point.y * invertScale };
};

export const filterOverlap = (annotations: CanvasAnnotation[]) => {
  const highestPriorityTheme = new Map<string, AnnotationTheme | undefined>();
  const highestPriority = new Map<string, number | undefined>();
  const hadDuplicates = new Set<string>();
  const seen = new Set<string>();
  return annotations
    .filter((annotation) => {
      const p = highestPriority.get(annotation.componentId) ?? 0;
      const componentPriority = annotation.priority ?? 0;
      if (componentPriority > p) {
        highestPriorityTheme.set(annotation.componentId, annotation.theme);
        highestPriority.set(annotation.componentId, componentPriority);
      }

      if (seen.has(annotation.componentId)) {
        hadDuplicates.add(annotation.componentId);
        return false;
      }

      seen.add(annotation.componentId);
      return true;
    })
    .map((annotation) => {
      if (hadDuplicates.has(annotation.componentId)) {
        return {
          ...annotation,
          theme: highestPriorityTheme.get(annotation.componentId) ?? annotation.theme,
        };
      }
      return { ...annotation };
    });
};
