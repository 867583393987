import React, { useCallback, useMemo, useRef, useState } from "react";
import "./canvasSearch.scss";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { Input } from "../other/Input";
import { Component } from "@blueplan/types";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import classNames from "classnames";
import { useFireBrowserEvent } from "../projects/planManagment/useFireBrowserEvent";
import { SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT } from "../markdown/PageRefLink";
import { fullPlanComponentsSelector } from "../../atom/fullPlanAtom";

export const CanvasSearch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const fullPlanComponents = useRecoilValue(fullPlanComponentsSelector);
  const [results, setResults] = useState<Component[]>([]);
  const [isDone, setIsDone] = useState(false);
  const fireShowComponent = useFireBrowserEvent(SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT);

  const onHover = () => {
    setIsOpen(true);
    setTimeout(() => {
      inputRef?.current?.focus();
    });
  };

  const onSearch = _.debounce((text: string) => {
    if (!text) {
      setResults([]);
      setIsDone(true);
      return;
    }

    const filteredResults = fullPlanComponents.filter(
      (component) =>
        component.data.title.toLowerCase().includes(text.toLowerCase()) ||
        component.data.categories.join(" ").toLowerCase().includes(text.toLowerCase())
    );
    setResults(filteredResults);
    setIsDone(true);
  }, 300);

  const handleInputChange = (text: string) => {
    setIsDone(false);
    setSearchText(text);
    onSearch(text);
  };

  const handleResultClick = useCallback(
    (component: Component) => {
      setIsOpen(false);
      fireShowComponent({
        pageIndex: (component.pageIndex ?? 0) + 1,
        componentId: component.componentId,
      });
    },
    [fireShowComponent]
  );

  const showResults = useMemo(
    () => isOpen && results.length > 0 && searchText.length > 0,
    [isOpen, results.length, searchText.length]
  );

  const searchResultsItems = useMemo(
    () =>
      results.slice(0, 30).map((result) => (
        <div
          key={result.componentId}
          className="result flex"
          onClick={() => handleResultClick(result)}
        >
          <div className="title">
            {result.data.title}
            <div className="categories flex">
              {result.data.categories.map((i) => (
                <div className="item" key={i}>
                  {i}
                </div>
              ))}
            </div>
          </div>
          <div className="page-number">Page {(result.pageIndex ?? 0) + 1}</div>
        </div>
      )),
    [results, handleResultClick]
  );

  return (
    <div
      className={classNames("canvas-search", {
        open: isOpen,
      })}
      onMouseEnter={onHover}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="flex search-box">
        <FaMagnifyingGlass />{" "}
        <Input
          style={{ display: isOpen ? "block" : "none" }}
          externalRef={inputRef}
          value={searchText}
          placeholder="Search"
          onChange={handleInputChange}
        />
      </div>
      <div className="counter" style={{ display: showResults ? "flex" : "none" }}>
        {results.length} results
      </div>
      <div
        className="no result"
        style={{
          display: isOpen && isDone && searchText && !results.length ? "flex" : "none",
        }}
      >
        Can't find anything
      </div>
      <div className="results" style={{ display: showResults ? "flex" : "none" }}>
        {searchResultsItems}
      </div>
    </div>
  );
};
