import React, { useState } from "react";
import { Rect, XYPoint } from "@blueplan/types";
import { Tooltip } from "react-tooltip";
import { planQaApi } from "../../../api/PlanQaApi";
import { useCurrenPlanQaData } from "../../../atom/planQaAtom";
import { Dropdown } from "@blueplan/core/src/uiComponents/Dropdown";
import "./selectCategoryAndAddAnnotationPopup.scss";

const categoryOptions = ["legend", "floorplan", "text", "starers", "toilets", "plan detail"];

export const useSelectCategoryAndAddAnnotationPopup = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [rect, setRect] = useState<Rect | null>(null);
  const [planQaId, setPlanQaId] = useState<string>("");
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState<XYPoint | null>(null);
  const currentPlanQa = useCurrenPlanQaData();

  const handleSubmit = (category: string) => {
    if (!rect) {
      return;
    }

    planQaApi.addAnnotation(planQaId, pageNumber, rect, category).then((data) => {
      currentPlanQa.update(data);
      currentPlanQa.endLoading();
    });
    setShow(false);
  };

  const SelectCategoryAndAddAnnotationPopup = (
    <Tooltip
      anchorSelect="body"
      id="select-category-and-add-annotation-popup"
      isOpen={false}
      position={position ?? { x: -100, y: -100 }}
      clickable
    >
      <div>
        <Dropdown<string>
          values={categoryOptions}
          value={"Floorplan"}
          onChange={handleSubmit}
          placeholder="Select category"
        />
      </div>
    </Tooltip>
  );

  const showCategoryAndAddAnnotationPopup = ({
    position,
    planQaId,
    rect,
    pageNumber,
  }: {
    position: XYPoint;
    planQaId: string;
    rect: Rect;
    pageNumber: number;
  }) => {
    setPosition(position);
    setShow(true);
    setRect(rect);
    setPlanQaId(planQaId);
    setPageNumber(pageNumber);
  };

  return { SelectCategoryAndAddAnnotationPopup, showCategoryAndAddAnnotationPopup };
};
