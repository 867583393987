import React from "react";
import ReactDOM from "react-dom/client";
import "@blueplan/core/src/theme/theme.scss";
import "@blueplan/core/src/uiComponents/loaders.scss";
import "@blueplan/core/src/report/complianceReport.scss";
import "./styles/pill.scss";
import "./styles/sideMenuStyle.scss";
import "./styles/tableTheme.scss";
import "./styles/transitions.scss";
import App from "./components/app/App";
import reportWebVitals from "./reportWebVitals";
import "react-loading-skeleton/dist/skeleton.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);

reportWebVitals();
