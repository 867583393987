import { atom } from "recoil";
import { DataState } from "@blueplan/types";
import { PlanModel, ProjectModel } from "@blueplan/types";

interface CurrentProjectAtom {
  state: DataState;
  plansState: DataState;
  model: ProjectModel | null;
  plans: PlanModel[];
}

export const currentProjectsAtom = atom<CurrentProjectAtom>({
  key: "currentProjectsAtom",
  default: {
    state: DataState.NOT_LOADED,
    plansState: DataState.NOT_LOADED,
    model: null,
    plans: [],
  },
});
