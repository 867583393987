import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import "./projectsPage.scss";
import { ROUTER_PATHS } from "../../app/routerPaths";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "@blueplan/core/src/auth/serverAuthFinishedAtom";
import { OrgAvatar } from "../../app/OrgAvatar";
import classNames from "classnames";
import { FaGear } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { UserAvatar } from "../../app/UserAvatar";
import { iconSvg } from "../../../util/LogoSvg";
import { USER_MENU_TOOLTIP_ID, UserMenu } from "../../app/user/UserMenu";
import { appMenuStateAtom } from "../../../atom/MenuStateAtom";
import { show as showIntercom } from "@intercom/messenger-js-sdk";

const matchListOfRoutes = (list: string[], currentPath: string) =>
  list.some(
    (path) =>
      matchPath(
        {
          path,
        },
        currentPath
      ) !== null
  );

interface MenuItemProps {
  link: string;
  text: string;
  icon: React.ReactNode | null;
  level?: number;
  otherSelectedPaths?: string[];
  selectedFn?: (path: string) => boolean;
}

const MenuItem = ({
  link,
  text,
  icon = null,
  level = 0,
  otherSelectedPaths = [],
  selectedFn = () => false,
}: MenuItemProps) => {
  const location = useLocation();
  const selected =
    matchPath(
      {
        path: link,
      },
      location.pathname
    ) ||
    matchListOfRoutes(otherSelectedPaths, location.pathname) ||
    selectedFn(location.pathname);
  return (
    <Link
      to={link}
      className={classNames("menu-item title clickable", {
        selected: selected,
        "level-one": level === 1,
        "level-two": level === 2,
      })}
    >
      <div className="icon">{icon}</div>
      <span className="text">{text}</span>
    </Link>
  );
};

export const AppSideMenu = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  const appMenuState = useRecoilValue(appMenuStateAtom);

  return (
    <div
      className={classNames("flex side-menu", {
        collapse: appMenuState.collapse,
      })}
    >
      <div className="flex top-name inter-font">
        <OrgAvatar />
        <span className="org-name">{appLevelUser?.oktaOrg?.display_name}</span>
      </div>
      <div className="menu flex">
        <MenuItem
          link={ROUTER_PATHS.ROOT}
          text="Projects"
          icon={iconSvg}
          selectedFn={(p) => p.startsWith("/project/")}
        />
        <MenuItem link={ROUTER_PATHS.ORGANIZATION} text="Organization" icon={<FaGear />} />
        <div className={classNames("menu-item title clickable")} onClick={showIntercom}>
          <div className="icon">
            <BiSupport />
          </div>
          <span className="text">Support</span>
        </div>
      </div>
      <UserMenu />
      <div className="user-section flex" data-tooltip-id={USER_MENU_TOOLTIP_ID}>
        <UserAvatar />
        <span className="user-name">
          {appLevelUser?.given_name} {appLevelUser?.family_name}
        </span>
      </div>
    </div>
  );
};
