import React, { useMemo } from "react";
import "./buildingCodeCard.scss";
import { CityName } from "@blueplan/types";
import { MultiLinearLoader } from "../../other/MultiLinearLoader";
import parse from "html-react-parser";
import { marked } from "marked";
import { useGetBuildingCode } from "../hook/useGetBuildingCode";
import { SectionRef, useFetchBuildingCode } from "../hook/useFetchBuildingCode";

interface BuildingCodeCardProps {
  fullSectionPath: string;
  cityName?: CityName;
  codeYear?: number;
  bookId?: string;
}

export const BuildingCodeCard = ({
  fullSectionPath,
  cityName,
  codeYear,
  bookId,
}: BuildingCodeCardProps) => {
  const fetchBuildingCode = useFetchBuildingCode();
  const getBuildingCode = useGetBuildingCode();
  const sectionRef: SectionRef = {
    cityName,
    codeYear,
    fullSectionPath,
    bookId,
  };
  fetchBuildingCode([sectionRef]);
  const buildingCode = getBuildingCode(sectionRef);

  const details = useMemo(() => {
    if (!buildingCode) {
      return null;
    }

    return (
      <div>
        <div className="title">{buildingCode.title}</div>
        <div className="section">section {buildingCode.fullSectionPath}</div>
        <div>{parse(marked.parse(buildingCode.text) as string)}</div>
      </div>
    );
  }, [buildingCode]);

  return <div className="inner">{buildingCode ? details : <MultiLinearLoader />}</div>;
};
