import React, { useCallback, useEffect, useState } from "react";
import "./canvasAnnotationMenu.scss";
import { FaLeftLong, FaRegTrashCan, FaRightLong } from "react-icons/fa6";
import { planQaApi } from "../../api/PlanQaApi";
import { useRecoilValue } from "recoil";
import { useCurrenPlanQaData } from "../../atom/planQaAtom";
import { SpinnerLoader } from "@blueplan/core/src/uiComponents/SpinnerLoader";
import { Button, ButtonColor } from "@blueplan/core/src/theme/Button";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTER_PATHS } from "../app/routerPaths";
import { currentProjectsAtom } from "@blueplan/core/src/atom/currentProjectAtom";
import classNames from "classnames";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { useBrowserEventListenerEvent } from "@blueplan/core/src/hook/useBrowserEventListenerEvent";

interface CanvasRectMenuProps {
  annotationKey: string | null;
  resetAnnotationKey: () => void;
  menuRef: React.MutableRefObject<HTMLDivElement | null> | null;
  float: boolean;
}

export const CanvasAnnotationMenu = ({
  annotationKey,
  resetAnnotationKey,
  menuRef,
  float,
}: CanvasRectMenuProps) => {
  const [localLoading, setLocalLoading] = useState(false);
  const currentProject = useRecoilValue(currentProjectsAtom);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const { containerWidth } = useRecoilValue(pageImageSizeAtom);
  const currentPlanQa = useCurrenPlanQaData();

  useEffect(() => {
    setTitle(currentPlanQa.nullableData()?.title || "");
  }, [setTitle, currentPlanQa]);

  const handleRemoveAnnotation = useCallback(() => {
    if (!currentPlanQa.hasData() || !annotationKey) {
      return;
    }

    setLocalLoading(true);
    planQaApi.removeAnnotation(currentPlanQa.data().planQaId, annotationKey).then((data) => {
      currentPlanQa.update(data);
      currentPlanQa.endLoading();
      setLocalLoading(false);
      resetAnnotationKey();
    });
  }, [currentPlanQa, annotationKey, setLocalLoading, resetAnnotationKey]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Backspace") {
      handleRemoveAnnotation();
    }
  };

  useBrowserEventListenerEvent("keydown", handleKeyDown);

  const handleChangeTitle = useCallback(
    (title: string) => {
      if (!currentPlanQa.hasData()) {
        return;
      }

      setTitle(title);
      planQaApi.updateTitle(currentPlanQa.data().planQaId, title);
      currentPlanQa.updateFn((old) => {
        if (!old) {
          return old;
        }

        return { ...old, data: { ...old, title } };
      });
    },
    [currentPlanQa]
  );

  const handleTitleInputBlur = () => {
    if (title.length === 0) {
      handleChangeTitle("Untitled inquiry");
    }
  };

  return (
    <div
      className={classNames("canvas-rect-menu flex", { float })}
      ref={menuRef}
      style={{
        width: float ? containerWidth : "auto",
      }}
    >
      <Button
        color={ButtonColor.TRANSPARENT}
        classes="remove-annotation"
        onClick={() =>
          navigate(
            "/" +
              generatePath(ROUTER_PATHS.PROJECT_SETTINGS, {
                projectId: currentProject.model?.projectId,
              })
          )
        }
        IconLeft={FaLeftLong}
      >
        Back
      </Button>
      <Button
        color={ButtonColor.TRANSPARENT}
        classes="remove-annotation"
        onClick={handleRemoveAnnotation}
        disabled={!annotationKey}
        IconLeft={localLoading ? SpinnerLoader : FaRegTrashCan}
      >
        Remove
      </Button>
      <input
        value={title}
        onChange={(e) => handleChangeTitle(e.target.value)}
        onBlur={handleTitleInputBlur}
      />
      <Button
        color={ButtonColor.BRAND}
        classes="continue"
        onClick={() =>
          navigate(
            "/" +
              generatePath(ROUTER_PATHS.PLAN_QA, {
                projectId: currentProject.model?.projectId,
                planId: currentPlanQa.data().planId,
                planQaId: currentPlanQa.data().planQaId,
              })
          )
        }
        IconRight={FaRightLong}
      >
        Ask a question
      </Button>
    </div>
  );
};
