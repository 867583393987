import { SectionComplianceReport } from "@blueplan/types";
import React from "react";
import classNames from "classnames";
import { getReportResultClass } from "@blueplan/core/src/report/reportUtil";
import { codeSymbol } from "../../util/codeUtil";
import "./sectionCanvasPicker.scss";
import { useFireBrowserEvent } from "../projects/planManagment/useFireBrowserEvent";
import { CLICK_ON_PLAN_ANNOTATION_EVENT } from "./PageCanvas";

interface SectionCanvasPickerProps {
  relevantSections: SectionComplianceReport[];
  close: () => void;
}

export const SectionCanvasPicker = ({ relevantSections, close }: SectionCanvasPickerProps) => {
  const clickOnPlanAnnotation = useFireBrowserEvent(CLICK_ON_PLAN_ANNOTATION_EVENT);

  const items = relevantSections.map((section) => (
    <div
      key={section.sectionComplianceId}
      className={classNames("section", getReportResultClass(section.finalAssessment))}
      onClick={() => {
        clickOnPlanAnnotation(section.sectionComplianceId);
        close();
      }}
    >
      {codeSymbol(section.buildingCode)} {section.buildingCode.fullSectionPath} @{" "}
      {section.buildingCode.year}
    </div>
  ));

  return (
    <div className="section-canvas-picker flex">
      <div className="title">Relevant sections</div>
      {items}
    </div>
  );
};
