import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "@blueplan/core/src/auth/serverAuthFinishedAtom";
import "./userAvatar.scss";

export const UserAvatar = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  return (
    <div className="user-avatar">
      {appLevelUser?.picture ? (
        <img src={appLevelUser.picture} />
      ) : (
        <span>{appLevelUser?.name?.charAt(0)}</span>
      )}
    </div>
  );
};
