import { useAnnotationCanvasLayer } from "./useAnnotationCanvasLayer";
import { PlanPage, Rect, XYPoint } from "@blueplan/types";
import { CanvasAnnotation } from "./util/canvasAnnotationUtil";
import { useFlashingRectangleCanvasLayer } from "./useFlashingRectangleCanvasLayer";

export const useCreateAllCanvasLayers = (
  page: PlanPage,
  filteredAnnotations: CanvasAnnotation[],
  handleAddAnnotation: (pageNumber: number, rect: Rect) => void,
  handleClickOnAnnotation: (annotationKey: string | null, point: XYPoint | null) => void
) => {
  const AnnotationCanvas = useAnnotationCanvasLayer(
    filteredAnnotations,
    handleAddAnnotation,
    handleClickOnAnnotation
  );

  const SelectedAnnotationCanvas = useFlashingRectangleCanvasLayer();

  return (
    <>
      {SelectedAnnotationCanvas}
      {AnnotationCanvas}
    </>
  );
};
