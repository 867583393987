import { useCallback, useEffect, useState } from "react";
import { PlanAnnotation, Rect, XYPoint } from "@blueplan/types";
import { throttle } from "lodash";
import { annotationForPainter } from "./overlayUtils";
import "./canvas.scss";
import {
  filterOverlap,
  findFirstRectForPoint,
  scaleUpPoint,
  scaleUpRect,
} from "./util/canvasRectUtil";
import { CanvasAnnotation } from "./util/canvasAnnotationUtil";
import { reactForAnnotation } from "./painter/annotationPainter";
import { clearRectPainter } from "./painter/rectPainter";
import { useCanvasBase } from "./useCanvasBase";
import { MoveEvent, useDrag } from "./useDrag";
import { useRecoilValue } from "recoil";
import { pageImageSizeAtom } from "../../atom/pageImageSizeAtom";
import { activeCanvasContextAtom } from "../projects/planManagment/PlanViewer";

export const useAnnotationCanvasLayer = (
  filteredAnnotations: CanvasAnnotation[],
  handleAddAnnotation: (pageNumber: number, rect: Rect, event: MoveEvent | undefined) => void,
  handleSelectedAnnotationKey: (key: string | null, point: XYPoint | null) => void
) => {
  const { pageIndex } = useRecoilValue(activeCanvasContextAtom);
  const { scale, scaleOverDpi, zoom, dpi, originalImageWidth, originalImageHeight } =
    useRecoilValue(pageImageSizeAtom);
  const [currentRect, setCurrentRect] = useState<Rect | null>(null);

  const handleRectMove = useCallback(
    (rect: Rect, isNewRect: boolean, event: MoveEvent | undefined) => {
      setCurrentRect(isNewRect ? null : scaleUpRect(rect, scaleOverDpi * zoom));
      if (isNewRect) {
        handleAddAnnotation(pageIndex, scaleUpRect(rect, scale * (1 / dpi)), event);
      }
    },
    [setCurrentRect, pageIndex, handleAddAnnotation, dpi, scale, scaleOverDpi, zoom]
  );

  const { handleMouseDown } = useDrag(handleRectMove);

  const { drawFrame, canvasRef, Canvas } = useCanvasBase("annotations", handleMouseDown);
  const [currentHoverPoint, setCurrentHoverPoint] = useState<XYPoint>({
    x: -1,
    y: -1,
  });
  const [currentClickPoint, setCurrentClickPoint] = useState<XYPoint>({
    x: -1,
    y: -1,
  });

  const findRect = useCallback(
    (point: XYPoint) => {
      return findFirstRectForPoint(filteredAnnotations, point, pageIndex);
    },
    [pageIndex, filteredAnnotations]
  );

  useEffect(() => {
    const handleMouseMove = throttle((e) => {
      const { offsetX, offsetY } = e;
      if (offsetY < 0 || offsetX < 0) {
        return;
      }
      // For optimization, set the hover rect here instead of the big useEffect and avoid re-render unless there is a
      // hovered rect.
      setCurrentHoverPoint(scaleUpPoint({ x: offsetX, y: offsetY }, scaleOverDpi));
    }, 100);
    canvasRef.current?.addEventListener("mousemove", handleMouseMove);

    const ref = canvasRef.current;
    return () => {
      ref?.removeEventListener("mousemove", handleMouseMove);
    };
  }, [canvasRef, findRect, scaleOverDpi]);

  useEffect(() => {
    const handleClick = throttle((e) => {
      const { offsetX, offsetY } = e;
      if (offsetY < 0 || offsetX < 0) {
        return;
      }

      const point = scaleUpPoint({ x: offsetX, y: offsetY }, scaleOverDpi);
      setCurrentClickPoint(point);
      const clickOnAnnotation = findRect(point);
      if (clickOnAnnotation) {
        handleSelectedAnnotationKey(clickOnAnnotation.key, { x: e.x, y: e.y });
      } else {
        handleSelectedAnnotationKey(null, null);
      }
    }, 100);
    canvasRef.current?.addEventListener("click", handleClick);

    const ref = canvasRef.current;
    return () => {
      ref?.removeEventListener("click", handleClick);
    };
  }, [canvasRef, findRect, handleSelectedAnnotationKey, currentClickPoint, scaleOverDpi]);

  useEffect(() => {
    const painters = [clearRectPainter(originalImageWidth * dpi, originalImageHeight * dpi)];
    if (currentRect) {
      const annotation: PlanAnnotation = {
        rect: currentRect,
        label: "",
        id: "",
        planQaId: "",
        pageNumber: pageIndex,
        image: { bucket: "", id: "", md5: "" },
        ready: true,
        failed: false,
        deleted: false,
      };
      painters.push(reactForAnnotation([annotationForPainter(annotation)]));
    }

    const hoverOnAnnotation = findRect(currentHoverPoint);
    let hoverRectIndex = -1;
    if (hoverOnAnnotation) {
      hoverRectIndex = hoverOnAnnotation.index;
    }

    const clickOnAnnotation = findRect(currentClickPoint);
    let clickRectIndex = -1;
    if (clickOnAnnotation) {
      clickRectIndex = clickOnAnnotation.index;
    }

    const rects: CanvasAnnotation[] = filteredAnnotations
      .filter((a) => a.pageNumber === pageIndex)
      .map((canvasAnnotation, index) => ({
        ...canvasAnnotation,
        isHovered: hoverRectIndex === index,
        isSelected: clickRectIndex === index,
      }));
    const filteredForOverlap = filterOverlap(rects);
    painters.push(reactForAnnotation(filteredForOverlap));
    drawFrame(painters);
  }, [
    scale,
    drawFrame,
    originalImageHeight,
    originalImageWidth,
    currentRect,
    pageIndex,
    currentHoverPoint,
    currentClickPoint,
    findRect,
    filteredAnnotations,
    dpi,
  ]);

  return Canvas;
};
