import React, { useCallback, useEffect, useRef } from "react";
import { useActivePlan } from "../projects/hook/useActivePlan";
import { formatImageUrl } from "@blueplan/core/src/util/imageUtil";
import "./pagesIndex.scss";
import classNames from "classnames";
import { useFireBrowserEvent } from "../projects/planManagment/useFireBrowserEvent";
import { RESET_COMPONENT_IN_PAGE_INDEX_EVENT } from "../markdown/PageRefLink";
import { useRecoilValue } from "recoil";
import { Tooltip } from "react-tooltip";
import { activeCanvasContextAtom } from "../projects/planManagment/PlanViewer";

interface PagesIndexProps {
  setPageIndex: (page: number) => void;
}

export const PagesIndex = ({ setPageIndex }: PagesIndexProps) => {
  const { activePlan } = useActivePlan();
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const resetSelectedComponent = useFireBrowserEvent(RESET_COMPONENT_IN_PAGE_INDEX_EVENT);
  const { pageIndex } = useRecoilValue(activeCanvasContextAtom);

  useEffect(() => {
    const currentRef = pageRefs.current[pageIndex];
    if (currentRef) {
      currentRef.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [pageIndex]);

  const handleClick = useCallback(
    (pageNumber: number) => {
      resetSelectedComponent();
      setPageIndex(pageNumber);
    },
    [setPageIndex, resetSelectedComponent]
  );

  if (!activePlan) {
    return null;
  }

  const pages = activePlan.pages.map((page, index) => (
    <div key={page.id} className="pages-index-group">
      <Tooltip id={`${page.id}-index`} place="right" offset={5} delayHide={0} delayShow={0}>
        {page.sheetDetails.sheetTitle}
      </Tooltip>
      <div
        key={page.pageNumber}
        className={classNames("item flex", {
          selected: pageIndex === page.pageNumber,
        })}
        ref={(el) => (pageRefs.current[index] = el)}
        data-tooltip-id={`${page.id}-index`}
        data-tooltip-delay-hide={1}
      >
        <img
          src={formatImageUrl(page.thumbnailBlob)}
          onClick={() => handleClick(page.pageNumber)}
          key={page.id}
        />
        <span>
          {page.pageNumber + 1} / {page.sheetDetails.sheetNumber}
        </span>
      </div>
    </div>
  ));
  return <div className="pages-index flex">{pages}</div>;
};
