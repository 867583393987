import React, { useCallback, useMemo, useRef } from "react";
import { Painter, runPainter } from "./painter/painterUtil";
import "./canvas.scss";
import { useCanvasSizeAndScale } from "./useCanvasSizeAndScale";
import { RenderStackItem, useRenderTaskQueue } from "../../hooks/useRenderTaskQueue";
import { clearRectPainter } from "./painter/flashRectPainter";

export interface ResetLayerOptions {
  delay?: number;
}

export const useCanvasBase = (
  id: string,
  handleMouseDown?: (event: React.MouseEvent | React.TouchEvent) => void
) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasSizeInfo = useCanvasSizeAndScale(canvasRef);
  const { scale } = canvasSizeInfo;

  const draw = useCallback(
    (item: RenderStackItem) => {
      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }

      const { painters } = item;
      for (const painter of painters) {
        ctx.scale(scale, scale);
        runPainter(ctx, painter);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      }
    },
    [canvasRef, scale]
  );

  const { addTask, resetQueue } = useRenderTaskQueue(draw);

  const drawFrame = useCallback(
    (painters: Painter[], delay?: number) => {
      addTask({ painters, delay });
    },
    [addTask]
  );

  const Canvas = useMemo(
    () => (
      <canvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        id={`canvas-layer-${id}`}
      ></canvas>
    ),
    [canvasRef, handleMouseDown, id]
  );

  const resetLayer = useCallback(
    async ({ delay }: ResetLayerOptions) => {
      addTask({ painters: [clearRectPainter()], delay });
      resetQueue();
    },
    [resetQueue, addTask]
  );

  return { Canvas, canvasRef, drawFrame, canvasSizeInfo, resetLayer };
};
