import React, { useMemo } from "react";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "@blueplan/core/src/auth/serverAuthFinishedAtom";
import { DataState } from "@blueplan/types";
import { AppLogoLoader } from "@blueplan/core/src/uiComponents/AppLogoLoader";
import { NoAuthPage } from "@blueplan/core/src/pages/noAuthPage/NoAuthPage";
import { ROUTER_PATHS } from "@blueplan/webapp/src/components/app/routerPaths";
import { useAppWithAuth } from "./useAppWithAuth";

interface PageBehindAuthProps {
  outlet: React.ReactNode;
  modalComponent?: JSX.Element | null;
}

export const PageBehindAuth = ({ outlet, modalComponent }: PageBehindAuthProps) => {
  useAppWithAuth(ROUTER_PATHS.ROOT);
  const { state } = useRecoilValue(serverAuthFinishedAtom);
  const outletWithProject = useMemo(() => {
    if (state === DataState.LOADING || state === DataState.NOT_LOADED) {
      return <AppLogoLoader />;
    } else if (state === DataState.LOADED) {
      return outlet;
    } else if (state === DataState.FAILED) {
      return <NoAuthPage rootPath={ROUTER_PATHS.ROOT}/>;
    }
  }, [state, outlet]);
  
  return (
    <div className="app-behind-loader">
      {modalComponent}
      <div className={classNames("outlet")} id="outlet">
        {outletWithProject}
      </div>
    </div>
  );
};
