import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";
import { authApi } from "@blueplan/core/src/api/authApi";
import { useRecoilState } from "recoil";
import { matchPath, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AuthenticationResult } from "@auth0/auth0-spa-js/src/global";
import { DataState } from "@blueplan/types";
import { serverAuthFinishedAtom } from "./serverAuthFinishedAtom";

const WEBSITE = "https://blueplanai.com/";

export const useAuth = (rootPath: string, postAuth?: () => void) => {
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout: authLogout,
  } = useAuth0();
  const [serverAuthState, setServerAuthState] = useRecoilState(serverAuthFinishedAtom);
  const [searchParams] = useSearchParams();
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    authLogout({ logoutParams: { returnTo: "https://blueplanai.com" } });
  };

  const handleAuth0Redirect = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      if (token && user) {
        setServerAuthState({
          state: DataState.LOADING,
          appLevelUser: undefined,
        });
        const appUser = await authApi.auth(token, user.org_id);
        setServerAuthState({
          state: DataState.LOADED,
          appLevelUser: appUser,
        });
        if(postAuth){
          postAuth();
        }
      } else if (!matchPath({ path: rootPath }, location.pathname)) {
        navigate(WEBSITE);
      }
    } catch (generic: unknown) {
      const e = generic as AuthenticationResult;
      if (e.error === "login_required" || e.error === "interaction_required") {
        console.error("error login: ", e);
        return;
      }
      console.error("error login: ", e);
      setServerAuthState({
        state: DataState.FAILED,
        appLevelUser: undefined,
      });
    }
  }, [setServerAuthState, navigate, getAccessTokenSilently, location.pathname, user]);

  const doAuth = useCallback(() => {
    const organization = searchParams.get("organization") || undefined;
    const invitation = searchParams.get("invitation") || undefined;
    if (organization && organization) {
      loginWithRedirect({
        authorizationParams: {
          invitation,
          organization,
        },
      });
    } else if (isAuthenticated) {
      handleAuth0Redirect();
    } else if (isLoading) {
      // NOOP
    } else {
      loginWithRedirect();
    }
  }, [loginWithRedirect, isLoading, isAuthenticated, handleAuth0Redirect, searchParams]);

  useEffect(() => {
    if (searchParams.get("error") === "invalid_request") {
      setServerAuthState({
        state: DataState.FAILED,
        appLevelUser: undefined,
      });
      localStorage.removeItem("authToken");
    }
  }, [searchParams, setServerAuthState]);

  return {
    isAuth: isAuthenticated && serverAuthState.state === DataState.LOADED,
    isLoading: isLoading || serverAuthState.state === DataState.LOADING,
    user,
    doAuth,
    logout,
  };
};
