import { useCallback } from "react";
import { useCurrentPlanReportData } from "../../../atom/currentPlanReportAtom";
import { planReportApi } from "@blueplan/core/src/api/planReportApi";

export const useFetchPlanReport = () => {
  const planReportData = useCurrentPlanReportData();

  const fetchPlanReport = useCallback(
    async (reportId: string, showLoader: boolean = true) => {
      if (showLoader) {
        planReportData.startLoading();
      }
      const planReport = await planReportApi.getReport(reportId);
      planReportData.update(planReport).endLoading();
      return planReport;
    },
    [planReportData]
  );

  return { fetchPlanReport };
};
