import { Rect } from "@blueplan/types";
import { calculateLineThickness } from "../util/canvasSizeUtil";

export const flashRectangleBordersPainter = (rect: Rect) => (ctx: CanvasRenderingContext2D) => {
  const { start, end } = rect;
  const x = Math.min(start.x, end.x);
  const y = Math.min(start.y, end.y);
  const minX = Math.min(start.x, end.x);
  const minY = Math.min(start.y, end.y);
  const w = Math.max(start.x, end.x) - minX;
  const h = Math.max(start.y, end.y) - minY;

  ctx.strokeStyle = "red";
  ctx.lineWidth = calculateLineThickness(ctx);
  ctx.strokeRect(x, y, w, h);
};

export const clearRectPainter = () => (ctx: CanvasRenderingContext2D) => {
  ctx.clearRect(0, 0, Number.MAX_VALUE, Number.MAX_VALUE);
};
