import { atom } from "recoil";
import { BuildingCodeRecord } from "@blueplan/types";

export interface BuildingCodeSectionsAtom {
  // City -> year -> section -> code
  codesByCityAndYear: Map<string, Map<number, Map<string, BuildingCodeRecord>>>;
  // bookId -> section -> code
  codesByBookId: Map<string, Map<string, BuildingCodeRecord>>;
  alreadyLoaded: Map<string, Set<string>>;
}

export const buildingCodeAtom = atom<BuildingCodeSectionsAtom>({
  key: "buildingCode",
  default: {
    codesByCityAndYear: new Map(),
    codesByBookId: new Map(),
    alreadyLoaded: new Map(),
  },
});
