import React, { useState } from "react";
import "./contact.scss";
import { gEvent } from "../../util/gtag";
import { contactApi } from "../../api/contactsApi";
import { Button, ButtonColor } from "@blueplan/core/src/theme/Button";
import { Dropdown } from "@blueplan/core/src/uiComponents/Dropdown";
import { NewContactRequest } from "../../model/contacts";

const profOptions = ["Expeditor", "Architect", "Developer", "Investor", "Other"];

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const Contact = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [form, setForm] = useState<NewContactRequest>({
    name: "",
    email: "",
    profession: profOptions[0],
    professionOther: "",
    freeForm: "",
  });

  const updateForm = (key: string, value: string) => {
    setForm((old) => ({ ...old, [key as keyof NewContactRequest]: value }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    updateForm(name, value);
  };

  const isValid =
    !!form.name &&
    validateEmail(form.email) &&
    (form.profession !== "Other" || !!form.professionOther) &&
    !!form.freeForm;

  const handleSubmit = () => {
    setIsSubmit(true);
    gEvent("contact_form", form);
    contactApi.send(form);
  };

  const submitEl = (
    <div className="submit">
      Thank you for being in touch, we will get back to you as soon as possible.
    </div>
  );

  const formEl = (
    <div className="contact-form">
      <div className="split-row">
        <div className="input">
          <label htmlFor="full-name">Name *</label>
          <input id="full-name" name="name" onChange={handleInputChange} autoFocus />
        </div>
        <div className="input">
          <label>Profession *</label>
          <Dropdown
            value={form.profession}
            values={profOptions}
            onChange={(val) => updateForm("profession", val)}
          />
        </div>
      </div>
      {form.profession === "Other" && (
        <div className="input">
          <label>Other profession *</label>
          <input
            placeholder="Other"
            name="professionOther"
            value={form.professionOther}
            onChange={handleInputChange}
          />
        </div>
      )}
      <div className="input">
        <label htmlFor="email">Email *</label>
        <input id="email" name="email" onChange={handleInputChange} />
      </div>
      <div className="input">
        <label htmlFor="email">Tell us about yourself *</label>
        <textarea name="freeForm" onChange={handleInputChange} />
      </div>
      <Button disabled={!isValid} onClick={handleSubmit} color={ButtonColor.BRAND}>
        Submit
      </Button>
    </div>
  );

  return (
    <div className="contact">
      <div className="contact contact-container flex no-flex-mobile">
        <div className="contact-info">
          <h2>Contact Us</h2>
          <p>Our new AI powered platform allows you to ensure zoning and code compliance!</p>
          <p>We are currently in beta and would love to connect and hear how we can be helpful.</p>
          <p>
            Please find us at <a href="mailto:dan@blueplanai.com">dan@blueplanai.com</a>
          </p>
        </div>
        {isSubmit ? submitEl : formEl}
      </div>
    </div>
  );
};
