import React, { useRef } from "react";
import { IconType } from "react-icons";
import "./input.scss";
import classNames from "classnames";

export interface InputProps {
  value: string;
  onChange: (value: string) => void;
  IconLeft?: IconType;
  externalRef?: React.MutableRefObject<HTMLInputElement | null>;
}

export const Input = ({
  value,
  onChange,
  IconLeft,
  externalRef,
  ...props
}: InputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const activeRef = externalRef || inputRef;

  const handleOnClick = () => {
    activeRef.current?.focus();
  };

  return (
    <div className="input flex" onClick={handleOnClick}>
      {IconLeft && <IconLeft className={classNames({ text: !!value && value.length })} />}
      <input ref={activeRef} {...props} value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};
