import { BaseApi } from "@blueplan/core/src/api/BaseApi";
import { SignedUrlResponse } from "@blueplan/types";

class SignedUrlApi extends BaseApi {
  async getSignedUrl(): Promise<SignedUrlResponse> {
    return this.get("signed-url");
  }

  async uploadFile(file: File, signedUrl: string) {
    await this.putFile(signedUrl, file);
  }
}

export const signedUrlApi = new SignedUrlApi("plan-qa");
