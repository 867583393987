import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { serverAuthFinishedAtom } from "@blueplan/core/src/auth/serverAuthFinishedAtom";
import "./organizationPage.scss";
import { OktaOrgMember, organizationApi } from "../../api/organizationApi";

export const OrganizationPage = () => {
  const { appLevelUser } = useRecoilValue(serverAuthFinishedAtom);
  const [members, setMembers] = useState<OktaOrgMember[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const items = await organizationApi.members();
      setMembers(items);
      setIsLoading(true);
    };
    if (!members.length && !isLoading) {
      load();
    }
  }, [members, isLoading]);

  const membersList = useMemo(
    () =>
      members.map((member) => (
        <div className="member" key={member.user_id}>
          <div className="name">{member.name}</div>
          <img src={member.picture} />
        </div>
      )),
    [members]
  );

  if (!appLevelUser) {
    return null;
  }

  return (
    <div className="org-page">
      <div className="details flex">
        <h2>{appLevelUser.oktaOrg?.display_name}</h2>
        <img src={appLevelUser?.oktaOrg?.branding?.logo_url} />
      </div>
      <h2>Members</h2>
      <div className="members-list">{membersList}</div>
    </div>
  );
};
