import React from "react";
import "./pageRefLink.scss";
import { RiFocus3Line } from "react-icons/ri";
import { useFireBrowserEvent } from "../projects/planManagment/useFireBrowserEvent";

export const SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT = "show-page-in-page-index";
export const RESET_COMPONENT_IN_PAGE_INDEX_EVENT = "reset-component-in-page-index";

export interface ShowPageInPageView {
  pageIndex: string;
  componentId?: string;
}

interface PageRefLinkProps {
  page: string;
  componentId?: string | undefined;
  text: string;
  tag: string | undefined;
}

export const PageRefLink = ({ page, componentId, text, tag }: PageRefLinkProps) => {
  const fireShowComponent = useFireBrowserEvent(SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT);
  const showPage = () => {
    fireShowComponent({
      pageIndex: page,
      componentId,
    });
  };

  return (
    <span className="page-ref-link" onClick={showPage} data-tag={tag}>
      {text}
      <RiFocus3Line />
    </span>
  );
};
