import React, { ReactNode } from "react";
import "./box.scss";
import classNames from "classnames";

interface BoxProps {
  title?: ReactNode;
  actionItem?: ReactNode;
  body?: ReactNode;
  classes?: string;
  children?: ReactNode;
}

export const Box = ({ title, body, actionItem, classes, children }: BoxProps) => {
  return (
    <div className={classNames("box", classes)}>
      <div className="title flex">
        {title && <div className="content">{title}</div>}
        {actionItem && <div className="action-item">{actionItem}</div>}
      </div>
      <div className="body">
        {body}
        {children}
      </div>
    </div>
  );
};
