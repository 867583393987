import { ResetLayerOptions, useCanvasBase } from "./useCanvasBase";
import { flashRectangleBordersPainter } from "./painter/flashRectPainter";
import { useBrowserEventListenerEvent } from "@blueplan/core/src/hook/useBrowserEventListenerEvent";
import {
  RESET_COMPONENT_IN_PAGE_INDEX_EVENT,
  SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT,
  ShowPageInPageView,
} from "../markdown/PageRefLink";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { activeCanvasContextAtom } from "../projects/planManagment/PlanViewer";
import { fullPlanAnnotationsMapSelector } from "../../atom/fullPlanAtom";

export const useFlashingRectangleCanvasLayer = () => {
  const { drawFrame, Canvas, resetLayer } = useCanvasBase("selected-annotation");
  const [registeredComponentId, setRegisteredComponentId] = useState("");
  const fullPlanAnnotationsMap = useRecoilValue(fullPlanAnnotationsMapSelector);
  const { isReady } = useRecoilValue(activeCanvasContextAtom);

  useBrowserEventListenerEvent<ResetLayerOptions>(RESET_COMPONENT_IN_PAGE_INDEX_EVENT, resetLayer);

  const flashAnnotation = useCallback(() => {
    const annotation = fullPlanAnnotationsMap[registeredComponentId];
    if (!annotation) {
      return;
    }

    resetLayer({});
    drawFrame([flashRectangleBordersPainter(annotation.rect)], 200);
    resetLayer({ delay: 600 });
    drawFrame([flashRectangleBordersPainter(annotation.rect)], 800);
    resetLayer({ delay: 1000 });
  }, [drawFrame, fullPlanAnnotationsMap, registeredComponentId, resetLayer]);

  const registerFlash = (event: CustomEvent<ShowPageInPageView>) => {
    const { componentId } = event.detail as ShowPageInPageView;
    if (!componentId) {
      return;
    }

    setRegisteredComponentId(componentId);
  };

  useEffect(() => {
    if (!isReady || !registeredComponentId) {
      return;
    }

    flashAnnotation();
    setRegisteredComponentId("");
  }, [registeredComponentId, setRegisteredComponentId, isReady, flashAnnotation]);

  useBrowserEventListenerEvent(SHOW_PAGE_AND_COMPONENT_PAGE_INDEX_EVENT, registerFlash);

  return Canvas;
};
