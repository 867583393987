import { useResetRecoilState } from "recoil";
import { useEffect } from "react";
import { planApi } from "../../api/planApi";
import { fullPlanAtom, useFullPlanData } from "../../atom/fullPlanAtom";

export const useLoadFullPlanById = (planId: string | undefined) => {
  const resetFullPlanAtom = useResetRecoilState(fullPlanAtom);
  const fullPlanData = useFullPlanData();

  useEffect(() => {
    if (!planId) {
      resetFullPlanAtom();
      return;
    }

    if (fullPlanData.isLoading()) {
      return;
    }

    if (fullPlanData.nullableData()?.id === planId) {
      return;
    }

    fullPlanData.startLoading();
    planApi.getFullPlan(planId).then((plan) => {
      fullPlanData.update(plan);
      fullPlanData.endLoading();
    });
  }, [planId, fullPlanData, resetFullPlanAtom]);
};
