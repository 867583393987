import { User } from "@auth0/auth0-react";
import { OktaUserInfo } from "@blueplan/types";

export enum DataState {
  NOT_LOADED,
  LOADING,
  LOADED,
  FAILED,
}

export interface UIUser extends Omit<User, "email">, OktaUserInfo {}

export interface ServerAuthFinishedAtom {
  state: DataState;
  appLevelUser: UIUser | undefined;
}
