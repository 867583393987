import { useEffect } from "react";
import { useAuth } from "@blueplan/core/src/auth/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthorizationParams } from "@auth0/auth0-spa-js/src/global";
import { useAuth0 } from "@auth0/auth0-react";
import { useRecoilValue } from "recoil";
import { DataState } from "@blueplan/types";
import { serverAuthFinishedAtom } from "./serverAuthFinishedAtom";

export const useAppWithAuth = (rootPath: string) => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { doAuth } = useAuth(rootPath);
  const serverAuthState = useRecoilValue(serverAuthFinishedAtom);

  useEffect(() => {
    if (
      serverAuthState.state === DataState.LOADED ||
      serverAuthState.state === DataState.LOADING ||
      serverAuthState.state === DataState.FAILED
    ) {
      return;
    }
    doAuth();
  }, [doAuth, serverAuthState.state]);

  useEffect(() => {
    const authorizationParams: AuthorizationParams = {};
    if (searchParams.get("organization")) {
      authorizationParams.organization = searchParams.get("organization") as string;
    }

    if (searchParams.get("invitation")) {
      authorizationParams.invitation = searchParams.get("invitation") as string;
    }

    if (authorizationParams.invitation && authorizationParams.organization) {
      loginWithRedirect({ authorizationParams });
    }
  }, [searchParams, loginWithRedirect, navigate]);
};
