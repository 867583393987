import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { DataState } from "@blueplan/types";
import { currentProjectsAtom } from "@blueplan/core/src/atom/currentProjectAtom";
import { useNavigate, useParams } from "react-router-dom";
import { planApi } from "@blueplan/core/src/api/planApi";
import { projectsAtom } from "@blueplan/core/src/atom/projectsAtom";

export const useLoadCurrentProject = (rootPath: string) => {
  const projectsStore = useRecoilValue(projectsAtom);
  const [currentProject, setCurrentProject] = useRecoilState(currentProjectsAtom);
  const resetCurrentProject = useResetRecoilState(currentProjectsAtom);
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const isNewProject = !!projectId && currentProject.model?.projectId !== projectId;
    if (isNewProject && projectsStore.state === DataState.LOADED) {
      const model = projectsStore.list.find((p) => p.projectId === projectId);
      if (!model) {
        navigate(rootPath);
        resetCurrentProject();
        return;
      }

      planApi.getPlans(projectId).then((plans) =>
        setCurrentProject((old) => ({
          ...old,
          model,
          plans,
          plansState: DataState.LOADED,
        }))
      );
    } else if (!projectId) {
      resetCurrentProject();
    }
  }, [projectId, navigate, projectsStore, setCurrentProject, resetCurrentProject, currentProject]);
};
