import { BaseApi } from "@blueplan/core/src/api/BaseApi";
import { PlanQARequest, PlanQA, Rect } from "@blueplan/types";

class PlanQaApi extends BaseApi {
  async create(planId: string): Promise<PlanQA> {
    return this.get(`create?planId=${planId}`);
  }

  async addAnnotation(
    planQaId: string,
    pageNumber: number,
    rect: Rect,
    category: string
  ): Promise<PlanQA> {
    const body: PlanQARequest = {
      planQaId,
      pageNumber,
      rect,
      category,
    };
    return this.post(`add-annotation?inqueryId=${planQaId}`, body);
  }

  async removeAnnotation(planQaId: string, annotationKey: string): Promise<PlanQA> {
    return this.get(`remove-annotation?inqueryId=${planQaId}&annotationKey=${annotationKey}`);
  }

  async getAll(planId: string): Promise<PlanQA[]> {
    return this.get(`all?planId=${planId}`);
  }

  updateTitle(planQaId: string, title: string) {
    this.get(`update-title?inqueryId=${planQaId}&title=${title}`);
  }
}

export const planQaApi = new PlanQaApi("plan-qa");
