import moment from "moment/moment";
import { assertDefined } from "@blueplan/core/src/util/assertions";

export const niceDate = (date: Date | null) => {
  if (date) {
    return moment(new Date(date)).format("M/D/YYYY @ h:mm");
  }
  return null;
};

export const formatCodeYear = (codeYear: number | null): string =>
  assertDefined(codeYear) === -1 ? "Investigation" : codeYear + "";
