import { useState, useEffect, useRef, useCallback } from "react";
import { Painter } from "../components/canvas/painter/painterUtil";
import { useRecoilValue } from "recoil";
import { activeCanvasContextAtom } from "../components/projects/planManagment/PlanViewer";

export interface RenderStackItem {
  painters: Painter[];
  delay?: number;
}

export const useRenderTaskQueue = (
  processTask: (task: RenderStackItem) => Promise<void> | void
) => {
  const [tasks, setTasks] = useState<RenderStackItem[]>([]);
  const taskQueueRef = useRef<RenderStackItem[]>([]);
  const processingRef = useRef(false);
  const { isReady } = useRecoilValue(activeCanvasContextAtom);

  const processQueue = useCallback(async () => {
    if (processingRef.current) return;
    processingRef.current = true;

    while (taskQueueRef.current.length > 0 && isReady) {
      const task = taskQueueRef.current.shift();
      if (task) {
        await new Promise((resolve) => setTimeout(resolve, task.delay ?? 0));
        await processTask(task);
      }
    }

    processingRef.current = false;
  }, [processTask, isReady]);

  const addTask = useCallback(
    (task: RenderStackItem) => {
      setTasks((prev) => [...prev, task]);
      taskQueueRef.current.push(task);
      processQueue();
    },
    [processQueue, taskQueueRef, setTasks]
  );

  useEffect(() => {
    if (!processingRef.current && taskQueueRef.current.length > 0) {
      processQueue();
    }
  }, [tasks, processQueue]);

  const resetQueue = useCallback(() => {
    setTasks([]);
  }, [setTasks]);

  return { addTask, resetQueue };
};
